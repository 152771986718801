import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
  Modal,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import { useGlobalContext } from "global/context";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import io from 'socket.io-client'; // Import socket.io-client
const PATH = process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string()
    .required("Description is required")
    .max(200, "Description cannot exceed 200 characters"),
});

function InstituteContactModal({ open, setOpen, institute }) {
  const { userData, api, socket, } = useGlobalContext();
  const socketRef = useRef();
  const [isConnect, setIsConnect] = useState(false);

  const handleSendMsg = (data) => {
    console.log("Receiver Details : ", institute)
    console.log("Sender details : ", userData)


    if (socketRef.current && socketRef.current.connected) {
      socketRef.current.emit("SEND_MSG", data);
      console.log("Message sent: ", data);
    }
  };

  const handleNotification = (type) => {
    socket.emit("send-notification", {
      sender_role: userData?.role,
      receiver_role: "institute",
      sender_name: userData?.first_name + " " + userData?.last_name,
      receiver_id: institute?.id,
      avatar: userData?.profile,
      type,
      sender_id: userData?.id,
      message: userData?.first_name + " " + userData?.last_name + " send you a message.",
      content_id: userData?.id + institute?.id,
    })
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      description: institute?.description || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        // Create the message data including form details
        const messageData = {
          sender_role: userData.role,
          receiver_role: "institute",
          msg: {
            title: values.title,
            description: values.description,
          },
          receiver: institute?.id,
          sender: userData?.id,
          room: userData?.id + institute?.id,
          time_to_send: new Date(),
        };

        // Send message data through socket
        handleSendMsg(messageData);
        console.log(messageData)
        handleNotification(5)

        formik.resetForm();
      } catch (err) {
        toast.error("Failed to contact institute");
      }
    },
  });

  useEffect(() => {
    const socket = io.connect(PATH)
    socketRef.current = socket;
    console.log("Socket:", socket);

    socket.on('connect', () => setIsConnect(true));
    socket.on('disconnect', () => setIsConnect(false));

    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    if (isConnect) {
      socketRef.current.emit('ADD_USER', userData);

      socketRef.current.on('USER_ADDED', (data) => {
        console.log("ADDED USER:", data);
      });

      socketRef.current.on("RECIVED_MSG", (data) => {
        console.log("Msg From Another User:", data);
      });
    }
  }, [isConnect]);

  if (!userData) return null;

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Paper
        sx={{
          width: 400,
          margin: "auto",
          padding: 4,
          borderRadius: 2,
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom>
          Share Information with Institute
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "bold" }}>Name:</Typography>
            <Typography variant="body2">{`${userData?.first_name} ${userData?.last_name}`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "bold" }}>Email:</Typography>
            <Typography variant="body2">{userData?.email}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "bold" }}>Phone Number:</Typography>
            <Typography variant="body2">{userData?.phone}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "bold" }}>Role:</Typography>
            <Typography variant="body2">{userData?.role}</Typography>
          </Grid>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="Subject"
              name="title"
              variant="outlined"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="Description"
              name="description"
              variant="outlined"
              multiline
              rows={5}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={
                `${formik.touched.description && formik.errors.description ? formik.errors.description : ''} (${formik.values.description.length}/200)`
              }
              fullWidth
            />
          </FormControl>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Paper>
    </Modal>
  );
}

export default InstituteContactModal;
