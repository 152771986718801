import { Favorite, HourglassTopTwoTone, MailTwoTone, PersonAddAltRounded, SmsFailedRounded, Star } from '@mui/icons-material';
import { Avatar, Button, IconButton, Chip } from '@mui/joy';
import { Rating } from '@mui/material';
import { useGlobalContext } from 'global/context';
import React from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

const ExpertCard = ({ user }) => {
    console.log("User ==== > ", user)
    const navigate = useNavigate();
    const { userData, api, setShowMessage, setMessageTo, setAuth } = useGlobalContext();

    const getRandomRating = () => {
        const minRating = 3.5; // Minimum rating
        const maxRating = 5; // Maximum rating
        const step = 0.5; // Increment step
      
        // Calculate random rating in steps of 0.5
        return Math.round((Math.random() * (maxRating - minRating) + minRating) / step) * step;
      };
    const handleConnect = async () => {
        try {
            const res = await api.post(`/app/connections/send-request`, {
                senderId: userData?.id,
                receiverId: user?.id
            });
            if (res?.status === 200) {
                user = { ...user, connectionStatus: 'pending' };
                toast.success(res?.data?.message);
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 401) {
                setAuth(true);
            } else {
                toast.error(error?.response?.data?.message);
            }
        }
    }

    const handleMessage = () => {
        setShowMessage(true);
        setMessageTo(user);
    }
    return (
        <>
            <div className="col mb-3">

                <div className="rounded-4 shadow hover-s relative" style={{ height: '100%' }}>
                    <div>
                        <div className=""
                            style={{
                                backgroundColor: '#4567b7', // Blue background color
                                color: 'white',
                                padding: '4px 8px', // Adjust padding if needed
                                borderRadius: '4px', // Adjust border radius if needed
                                borderTopLeftRadius: '16px', // Match the parent border-radius
                                borderTopRightRadius: '16px', // Match the parent border-radius
                                display: 'flex', // To use flexbox
                                justifyContent: 'center' // To center the text horizontally
                            }}>
                            <p className="fw-bold">{user?.profession}</p>
                        </div>
                    </div>
                    <div className='p-4'>
                        <div className="d-flex justify-content-center flex-wrap mb-2">
                            <Avatar alt="Remy Sharp" src={user?.avatar_url || 'https://static.vecteezy.com/system/resources/previews/034/784/595/original/little-buddha-cartoon-character-meditation-on-lotus-flower-vector.jpg'} size="lg"
                                sx={{
                                    height: '150px', width: '180px'
                                }} />

                        </div>
                        <Link to={`/${user?.username}`} className="d-flex flex-column align-items-center mb-3">
                            <h3 className='text-center'>{user?.first_name}</h3>
                            <h3 className='text-center mb-2'>{user?.last_name}</h3>
                            {/* <Rating
                                name="text-feedback"
                                value={user?.rating || 0}
                                readOnly
                                precision={0.5}
                                emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="inherit" />}
                            /> */}
                            <Rating
                                name="text-feedback"
                                value={getRandomRating()}  // Random rating
                                readOnly
                                precision={0.5}
                                emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                        </Link>
                        <div className="d-flex justify-content-between">
                            <div className="left-controls">
                                <IconButton
                                    color={user?.connectionStatus === "accepted" ? "info" :
                                        user?.connectionStatus === "pending" ? "warning" :
                                            user?.connectionStatus === "rejected" ? "danger" :
                                                "primary"
                                    }
                                    onClick={user?.connectionStatus === "accepted" ? handleMessage : user?.connectionStatus === "pending" ? null : handleConnect}>
                                    {user?.connectionStatus === "accepted" ? <MailTwoTone /> :
                                        user?.connectionStatus === "pending" ? <HourglassTopTwoTone /> :
                                            user?.connectionStatus === "rejected" ? <SmsFailedRounded /> :
                                                <PersonAddAltRounded />
                                    }
                                </IconButton>
                            </div>
                            <div className="right-controls">
                                <Button variant='soft' onClick={() => navigate(`/${user?.username}`)}>
                                    Profile
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpertCard;