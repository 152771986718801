import React, { useEffect, useState } from "react";
import { useGlobalContext } from "global/context";
import EventPage from './EventPage'

const EventDetails = () => {
    const { userData, api } = useGlobalContext();
    const [allEvents, setAllEvents] = useState([]);

    const getAllEvents = async () => {
        try {
            const res = await api.get(`/app/events/getEventByinstituteId/${userData?.id}`);
            if (res?.status === 200) {
                setAllEvents(res?.data?.results);
            }
        } catch (e) {
            console.error("Error fetching events:", e);
        }
    };

    useEffect(() => {
        getAllEvents();
    }, []);

    return (
        <>
        <div style={{ maxHeight: '750px', overflowY: 'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
            {allEvents.map((data, index) => (
                <div key={index}> {/* Use a unique key */}
                    
                    <EventPage data={data} key={index} />
                </div>
            ))}
        </div>
        <style>
            {`
            .scroll-container::-webkit-scrollbar {
                display: none;
            }
            `}
        </style>
        </>
    );
};

export default EventDetails;
