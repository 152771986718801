// Notifications.js
import { Avatar } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useGlobalContext } from 'global/context';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { Button, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NotificationAnswer from 'pages/Forum/components/Modals/NotificationAnswer';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  width: 400,  // Adjust width as needed
};
// NotificationItem Component
const NotificationItem = ({ data, type, onClick }) => {
  return (
    <div
      className='d-flex justify-content-between align-items-center p-3 hover-s shadow-sm rounded-3 mb-3'
      onClick={() => onClick(data)}
      style={{ cursor: 'pointer' }}
    >
      <div className='d-flex '>
        <Avatar
          alt={`${data.first_name} ${data.last_name}`}
          src="https://images.unsplash.com/flagged/photo-1557427161-4701a0fa2f42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80"
          className="mr-3"
          sx={{ width: "60px", height: "60px" }}
        />
        <div style={{ lineHeight: "20px" }}>
          <p className='text-dark'><strong>{`${data.first_name} ${data.last_name}`}</strong></p>
          <p className='fs-14'>{data?.message}</p>
          <p className='fs-11'>{moment(data?.createdAt).fromNow()}</p>
        </div>
      </div>
      <div>
        <img
          className='rounded-2'
          src='https://images.pexels.com/photos/8767784/pexels-photo-8767784.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
          alt='logo'
          style={{ width: "60px", height: "60px" }}
        />
      </div>
    </div>
  );
}

const AnswerDetailView = ({ data, onClose }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const showAnswerHandler = () => {
    setShowAnswer(prevState => !prevState);
  };

  if (!data) return null;

  return (
    <div className='detailed-view p-3 m-5 shadow-sm rounded-3 border border-success rounded'>
      <div className='flex justify-between'>
        <Button onClick={onClose} variant="contained" color="secondary" size="small">Close</Button>
        <Button onClick={showAnswerHandler} variant="contained" color="secondary" size="small">
          {showAnswer ? "Hide Answer" : "View Answer"}
        </Button>
      </div>
      <div className='d-flex align-items-center mt-3'>
        <div>
          <h3>Answer From</h3>
        </div>
        <Avatar
          alt={`${data?.message?.split(' ')[0]} ${data?.message?.split(' ')[1]}`}
          src="https://images.unsplash.com/flagged/photo-1557427161-4701a0fa2f42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80"
          className="mr-3"
          sx={{ width: "60px", height: "60px" }}
        />
        <div>
          <p className='text-dark'><strong>{data?.message?.split(' ')[1]}</strong></p>
          <p className='fs-14'>{data?.message}</p>
          <p className='fs-11'>{moment(data?.createdAt).fromNow()}</p>
        </div>
      </div>
      <div className='mt-3'>
        <p><strong>Answer Details:</strong></p>
        <p>{data?.message}</p>
      </div>

      {/* Conditionally render NotificationAnswer based on showAnswer */}
      {showAnswer && (
        <NotificationAnswer
          id={data?.id}
          question_id={data?.content_id}
          setAnswerLength={() => {}}
          data={data}
          showAnswer={showAnswer}
          setShowAnswer={setShowAnswer}
        />
      )}
    </div>
  );
};


// Modified DetailedView Component
const DetailedView = ({ data, onClose }) => {
  if (!data) return null;

  // Conditional rendering based on notification type
  return data.notification_type === 4 ? (
    <AnswerDetailView data={data} onClose={onClose} />
  ) : (
    <div className='detailed-view p-3 m-5 shadow-sm rounded-3 border border-success rounded'>
      <div className='flex justify-between'>
        <Button onClick={onClose} variant="contained" color="secondary" size="small">Close</Button>
        <Button variant="contained" color="secondary" size="small"> Go To </Button>
      </div>
      <div className='d-flex align-items-center mt-3'>
        <div>
          <h3>From </h3>
        </div>
        <Avatar
          alt={`${data?.message?.split(' ')[0]} ${data?.message?.split(' ')[1]}`}
          src="https://images.unsplash.com/flagged/photo-1557427161-4701a0fa2f42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80"
          className="mr-3"
          sx={{ width: "60px", height: "60px" }}
        />
        <div>
          <p className='text-dark'><strong>{data?.message?.split(' ')[1]}</strong></p>
          <p className='fs-14'>{data?.message}</p>
          <p className='fs-11'>{moment(data?.createdAt).fromNow()}</p>
        </div>
      </div>
      <div className='mt-3'>
        <p><strong>Details:</strong></p>
        <p>{data?.message}</p>
      </div>
    </div>
  );
};

// In Notifications Component, `DetailedView` is rendered without changes
// The `DetailedView` now conditionally renders `AnswerDetailView` if `data.type === 4`


// Notifications Component
function Notifications() {
  const { api, userData } = useGlobalContext();
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  // console.log("Notification : ", notifications)

  const getNotifications = async (role) => {
    try {
      const res = await api.get(`/app/notification?role=${role}`);
      if (res?.status === 200) {
        setNotifications(res?.data?.notifications);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message || "Error getting notifications");
    }
  }

  const handleNotificationClick = (data) => {
    setSelectedNotification(data);
  };

  const handleCloseDetailedView = () => {
    setSelectedNotification(null);
  };

  useEffect(() => {
    getNotifications(userData?.role);
  }, []);

  return (
    <div className='container mb-3 my-5 d-flex' style={{ minHeight: 500 }}>
      <div className='notification-list'>
        <h3 className='mb-4 fw-bold'>Notification <NotificationsNoneIcon className='fw-bold' color='info' /></h3>
        <div>
          {
            notifications.length ?
              notifications.map((notification, key) => (
                <NotificationItem key={key} data={notification} type={notification?.notification_type} onClick={handleNotificationClick} />
              ))
              :
              <h4>No notifications found</h4>
          }
        </div>
      </div>
      <div className='detailed-view-container ms-4' style={{ flex: 1 }}>
        <DetailedView data={selectedNotification} onClose={handleCloseDetailedView} />
      </div>
    </div>
  );
}

export default Notifications;