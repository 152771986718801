import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';



const truncateTitle = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    
    // Array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    // Get the month name by using the month index
    const monthName = monthNames[date.getMonth()];
    
    // Get the day
    const day = String(date.getDate()).padStart(2, '0');
    
    // Return the formatted date as "MonthName - Day"
    return `${monthName} - ${day}`;
  };

const MobileJobCard = ({ jd }) => {
  const navigate = useNavigate();
  const truncatedTitle = truncateTitle(jd?.job_title, 16);
  const truncatedDescription = truncateText(jd?.job_description.replace(/(<([^>]+)>)/gi, ""), 30); // Strip HTML tags before truncating
  const formattedDate = formatDate(jd?.expiry_date);
  const [showDate, setShowDate] = useState(false);

  const handleClick = () => {
    navigate(`/job/${jd.slug}`);
  };

  return (
    <div>
   
    <div onClick={handleClick} className="job-card-container p-2 bg-white shadow-md rounded-lg cursor-pointer hover:bg-gray-100">
  <div className="job-header flex justify-between items-center">
    <h3 className="font-bold text-sm text-blue-600">{truncatedTitle}</h3> {/* Reduced from text-lg to text-sm */}
    <span className="text-xs text-gray-500">{formattedDate}</span> {/* Reduced from text-sm to text-xs */}
  </div>
  {/* <p className="text-gray-600 text-xs my-2">{truncatedDescription}</p> */}
  <div className="job-details text-xs"> {/* Reduced from text-sm to text-xs */}
    <p className="text-gray-700">Location: {jd.job_location}</p>
    <p className="text-gray-700">Experience: {jd.experience}</p>
    <p className="text-gray-700">Salary: ₹{jd.salary_range}</p>
    <p className="text-gray-700">{jd.employment_type.join(', ')}</p>
    {/* <p className="text-gray-700">Work Schedule: {jd.work_schedule.start_time} - {jd.work_schedule.end_time}</p> */}
  </div>
</div>
</div>
  );
};

export default MobileJobCard;
