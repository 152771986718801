import React, { useEffect, useState } from "react";
import EventPage from './EventPage'
import { useGlobalContext } from "global/context";


const AllEvents = () => {
    const { api, userData } = useGlobalContext();
    const[allEvents, setAllEvents] = useState([])
   
    const getAllEvents = async () => {
        try {
          const res = await api.get(`/app/events/getAllevents`);
          if (res?.status === 200) {
            setAllEvents(res?.data?.results);
          }
        } catch (error) {
          console.log("Error fetching users:", error);
    
        }
      };

      useEffect(() => {
        getAllEvents();
      },[])

    return (
        <>
        <div style={{ maxHeight: '650px', overflowY: 'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
            {allEvents.map((data, index) => (
                <div key={index}> {/* Use a unique key */}
                    
                    <EventPage data={data} key={index} />
                </div>
            ))}
        </div>
        <style>
            {`
            .scroll-container::-webkit-scrollbar {
                display: none;
            }
            `}
        </style>
        </>
    );
};

export default AllEvents;
