import React from "react";
import UserCard from "./components/UserCard";
import { useState } from "react";
import { useEffect } from "react";
import { useGlobalContext } from "global/context";
import UserTicket from "./components/UserTicket";
import UserCard2 from "./components/UserCard2";
import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Link,
  Option,
  Radio,
  RadioGroup,
  Select,
} from "@mui/joy";
import {
  ButtonGroup,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  Rating,
} from "@mui/material";
import {
  CloseRounded,
  SearchTwoTone,
  Star,
  StarRounded,
} from "@mui/icons-material";
import SuggestedExperts from "../widgets/SuggestedExperts";
import UsersSkeleton from "./components/UsersSkeleton";
import { useLocation } from "react-router";
import { LoadingButton } from "@mui/lab";
import useDidMountEffect from "global/useDidMountEffect";
import { useMemo } from "react";
import Subjects from '../../../data/subject.json'
import debounce from "lodash.debounce";
import axios from "axios";
import { toast } from "react-hot-toast";
import { FilterList } from '@mui/icons-material';


const labels = {
  0.5: "Just Started",
  1: "Just Started+",
  1.5: "Still Learning",
  2: "Still Learning+",
  2.5: "Gaining Expertise",
  3: "Gaining Expertise+",
  3.5: "Expert Now",
  4: "Expert Now+",
  4.5: "Param Gyani",
  5: "Param Gyani+",
};

const UsersContainer = () => {
  window.scrollTo(0, 0);
  const routeLocation = useLocation();
  const { api, apiAuth, userData, users, setUsers } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState();
  const [totalUsersRole, setTotalUsersRole] = useState()
  const [filter, setFilter] = useState({
    teacher: true,
    student: false,
    professional: true,
    location: false,
    expertise: false,
    rating: false,
  });
  const [sort, setSort] = useState("recent");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [heading, setHeading] = useState("Experts");
  const [search, setSearch] = useState(routeLocation?.state?.keyword);
  const [expertises, setExpertises] = useState([]);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState();
  const [expertise, setExpertise] = useState();
  const [rating, setRating] = useState();
  const [loadMore, setLoadMore] = useState(false);
  const [showLoadButton, setShowLoadButton] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [isStudentExpanded, setIsStudentExpanded] = useState(false);
  const [isTeacherExpanded, setIsTeacherExpanded] = useState(false);
  const [selectedStudentClasses, setSelectedStudentClasses] = useState([]);
  const [selectedTeacherClasses, setSelectedTeacherClasses] = useState([]);
  const [isFilterVisible, setFilterVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const totalUsersR = totalUsersRole?.student + totalUsersRole?.teacher + totalUsersRole?.professional;
  let cancelToken;

  // resize component to hide filter
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleFilterVisibility = () => {
    setFilterVisible(!isFilterVisible);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const classOptions = ["1-5", "6-8", "9-10", "11", "12", "Graduation", "Post Graduation", "Diploma", "Freelancing Course"];

  // Handle "Teacher" checkbox change
  const handleTeacherChange = (e) => {
    const isChecked = e.target.checked;

    setFilter((prevFilter) => ({
      ...prevFilter,
      teacher: isChecked,
    }));

    // Select or deselect all class options for Teacher
    if (isChecked) {
      setSelectedTeacherClasses(classOptions); // Select all classes
    } else {
      setSelectedTeacherClasses([]); // Deselect all classes
    }
  };
  // Handle class checkbox change for Teacher
  const handleTeacherClassChange = (className) => {
    if (selectedTeacherClasses.includes(className)) {
      setSelectedTeacherClasses((prev) => {
        const updatedClasses = prev.filter((classItem) => classItem !== className);
        console.log("Selected Teacher Classes:", updatedClasses);
        return updatedClasses;
      });
    } else {
      setSelectedTeacherClasses((prev) => {
        const updatedClasses = [...prev, className];
        console.log("Selected Teacher Classes:", updatedClasses);
        return updatedClasses;
      });
    }
  };

  // Handle "Student" checkbox change
  const handleStudentChange = (e) => {
    const isChecked = e.target.checked;

    setFilter((prevFilter) => ({
      ...prevFilter,
      student: isChecked,
    }));

    // Select or deselect all class options for Student
    if (isChecked) {
      setSelectedStudentClasses(classOptions); // Select all classes
    } else {
      setSelectedStudentClasses([]); // Deselect all classes
    }
  };
  // Handle class checkbox change for Student
  const handleStudentClassChange = (className) => {
    if (selectedStudentClasses.includes(className)) {
      setSelectedStudentClasses((prev) => {
        const updatedClasses = prev.filter((classItem) => classItem !== className);
        console.log("Selected Student Classes:", updatedClasses);
        return updatedClasses;
      });
    } else {
      setSelectedStudentClasses((prev) => {
        const updatedClasses = [...prev, className];
        console.log("Selected Student Classes:", updatedClasses);
        return updatedClasses;
      });
    }
  };


  // Toggle class list visibility
  // Toggle the expansion of the Student classes
  const toggleStudentExpand = () => {
    setIsStudentExpanded(!isStudentExpanded);
  };

  // Toggle the expansion of the Teacher classes
  const toggleTeacherExpand = () => {
    setIsTeacherExpanded(!isTeacherExpanded);
  };


  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 1000);
  }, []);

  const getUsers = async () => {
    let role = [];
    if (filter?.student) {
      role.push("student");
    }
    if (filter?.teacher) {
      role.push("teacher");
    }
    if (filter?.professional) {
      role.push("professional");
    }
    role = JSON.stringify(role);
    // console.log("profession: ", profession);
    setLoading(true);
    toast.loading("Please wait...");
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
      setLoading(true);
      toast.loading("Please wait...");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    try {
      const res = await api.get(
        `app/user?sortBy=${sort}&search=${search}&teacher=${filter?.teacher}&student=${filter?.student}&professional=${filter?.professional}&location=${location}&skill=${expertise}&rating=${rating}&limit=${limit}&page=${page}&userId=${userData?.id}`,
        { cancelToken: cancelToken.token } //Pass the cancel token to the current request
      );
      if (res?.status === 200) {
        // console.log("Users : ", res?.data)
        setUsers(res?.data?.users);
        // setExpertises(res?.data?.expertises);
        setLocations(res?.data?.locations);
        setTotalUsers(res?.data?.totalUsers);
        setTotalUsersRole(res?.data?.roleCounts)
        setLoading(false);
        // console.log("Total : ", res?.data?.roleCounts)

        // Merge all expertise arrays into one, remove duplicates using a Set
        const allExpertises = [...new Set(res?.data?.expertises.flat())];

        // console.log("Flattened Expertises: ", allExpertises); // This will log all expertise values one by one

        // If you want to do something with each expertise (e.g., display them)
        allExpertises.forEach(expertise => {
          // console.log("Expertise: ", expertise);
          // You can also set state or perform other operations here
        });

        setExpertises(allExpertises);


        toast.dismiss();
        toast.success("Users successfully fetched!");
        console.log("Users : ", res?.data?.users?.length)

        if (users?.length < res?.data?.users[0]?.total_users) {
          setShowLoadButton(true); // More users to load
        } else {
          setShowLoadButton(false); // No more users to load
        }
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      if (error?.code === "ERR_CANCELED") {
        setLoading(true);
        toast.loading("Please wait...");
      } else {
        setLoading(false);
        toast.success("Something went wrong!");
      }
    }
  };
  // console.log(`User successfully fetched: ${totalUsersR}`);
  // console.log(`User success : ${users[0]?.total_users}`);
  // console.log("This is user data : ", users.length)
  // console.log("This is user data : ", users)

  users.map((user) => {
    // console.log("User by name : ",user.first_name)
  })

  const loadMoreUsers = async (newPage) => {
    let role = [];
    if (filter?.student) {
      role.push("student");
    }
    if (filter?.teacher) {
      role.push("teacher");
    }
    if (filter?.professional) {
      role.push("professional");
    }
    setLoadMore(true);
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
      setLoadMore(true);
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    try {
      const res = await api.get(
        `app/user?sortBy=${sort}&search=${search}&teacher=${filter?.teacher}&student=${filter?.student}&professional=${filter?.professional}&location=${location}&skill=${expertise}&rating=${rating}&limit=${limit}&page=${newPage}&userId=${userData?.id}`,
        { cancelToken: cancelToken.token } //Pass the cancel token to the current request
      );

      if (res?.status === 200) {
        const loadedData = res?.data?.users || [];
        console.log("loaded Data :", loadedData);


        setUsers(users.concat(loadedData)); // Append new data to existing users

        setPage(page + 1);
        setLoadMore(false);
        // Check if there are more users to load
        if (users?.length < totalUsersR) {
          setShowLoadButton(true); // No more users to load
        } else {
          setShowLoadButton(false); // More users to load
        }
      }
    } catch (error) {
      if (error?.code === "ERR_CANCELED") {
        // Handle cancellation of the request
        setLoadMore(true);
      } else {
        // Handle other errors
        toast.error("Something went wrong!");
        console.error("Error loading more data:", error);
      }
    } finally {
      setLoadMore(false); // Reset loading state
    }
  };

  const handleReset = () => {
    setFilter((prevFilter) => ({
      teacher: false,
      student: false,
      professional: false,
      location: false,
      expertise: false,
      rating: false,
    }));
    setRating((prevRating) => null);
    setLocation((prevLocation) => null);
    setExpertise((prevExpertise) => null);
    setPage((prevPage) => 1);
  };

  // const searchTimeout = setTimeout(()=> {
  //     console.log("Searching..")
  // }, 1000);

  // function clearSearch() {
  //     clearTimeout(searchTimeout);
  // }

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    getUsers();
  }, []);

  useDidMountEffect(() => {
    if (search) {
    }
    getUsers();
  }, [search]);

  useDidMountEffect(() => {
    console.log(
      `User successfully fetched sorted By : `,
      users,
      `Filter By : `,
      filter
    );
    getUsers();

  }, [sort, filter, location, expertise, rating]);

  const action = React.useRef(null);

  return (
    <>
      <div className="container my-3">
        <div className="row">
          <div className="col-12 col-lg-3">
            {search && (
              <Button
                variant="plain"
                className="forum-nav-item mb-3 p-3 w-100 shadow"
                onClick={() => {
                  setSearch("");
                  setHeading("Users");
                  setPage(1);
                }}
              >
                <h5>Switch to All Users</h5>
              </Button>
            )}
            {windowWidth < 992 && (
              <div className="mb-2 d-flex align-items-center justify-between ">
                <FormControl variant="outlined" className=" rounded flex-grow-1 me-2">
                  <Input
                    color="plain"
                    id="search"
                    type="text"
                    className="rounded-4 px-3 shadow"
                    name="search"
                    onChange={(e) => {
                      debouncedResults(e);
                      setPage(1);
                    }}
                    endDecorator={
                      <InputAdornment position="end">
                        <IconButton
                          variant="plain"
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setHeading(`Search Results`);
                            setPage(1);
                            getUsers();
                          }}
                          edge="end"
                        >
                          <SearchTwoTone />
                        </IconButton>
                      </InputAdornment>
                    }
                    // label="Password"
                    placeholder="Search user by name or expertise or location"
                  />
                </FormControl>
                <div style={{ width: '10%' }}  className="d-flex justify-content-end">
                  <IconButton
                    onClick={toggleFilterVisibility}
                    sx={{
                      backgroundColor: 'transparent',
                      color: 'black',
                    }}
                  >
                    <FilterList />
                  </IconButton>
                </div>
              </div>
            )}
            {(windowWidth >= 992 || isFilterVisible) && (
              <div className="filters mb-3 rounded-3 shadow p-2 p-lg-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-2">Filter By</h5>
                  <div>
                    <Button
                      color="neutral"
                      variant="plain"
                      size="sm"
                      className="text-capitalize"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
                <FormGroup>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <Checkbox
                        color="neutral"
                        disabled={false}
                        label="Teacher"
                        size="md"
                        variant="soft"
                        checked={filter?.teacher}
                        onChange={(e) => {
                          handleTeacherChange(e);
                          setPage(1);
                        }}
                      />
                    </div>
                    <div>
                      <IconButton
                        onClick={toggleTeacherExpand}
                        sx={{
                          padding: 0, // Remove extra padding
                          backgroundColor: 'transparent', // Remove background color
                          '&:hover': {
                            backgroundColor: 'transparent', // Ensure no background on hover
                          },
                          color: 'black', // Set the icon color to black
                        }}
                      >
                        {isTeacherExpanded ? <Remove sx={{ fontSize: '18px' }} /> : <Add sx={{ fontSize: '18px' }} />}
                      </IconButton>
                    </div>
                  </div>
                  {/* Conditionally render class checkboxes if Teacher is selected */}
                  {isTeacherExpanded && filter.teacher && (
                    <div
                      style={{

                        marginLeft: "20px",
                        marginBottom: "4px",
                      }}
                    >
                      <div>
                        {classOptions.map((className) => (
                          <div
                            key={className}
                          >
                            <Checkbox
                              color="neutral"
                              disabled={false}
                              label={className}
                              size="md"
                              variant="soft"
                              checked={selectedTeacherClasses.includes(className)}
                              onChange={() => handleTeacherClassChange(className)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <Checkbox
                        color="neutral"
                        disabled={false}
                        label="Student"
                        size="md"
                        variant="soft"
                        checked={filter?.student}
                        onChange={(e) => {
                          handleStudentChange(e);
                          setPage(1); // Assuming setPage is available in your scope
                        }}
                      />
                    </div>
                    <div>
                      <IconButton
                        onClick={toggleStudentExpand}
                        sx={{
                          padding: 0, // Remove extra padding
                          backgroundColor: 'transparent', // Remove background color
                          '&:hover': {
                            backgroundColor: 'transparent', // Ensure no background on hover
                          },
                          color: 'black', // Set the icon color to black
                        }}
                      >
                        {isStudentExpanded ? <Remove sx={{ fontSize: '18px' }} /> : <Add sx={{ fontSize: '18px' }} />}
                      </IconButton>
                    </div>
                  </div>
                  {/* Conditionally render class checkboxes if Student is selected and expanded */}
                  {isStudentExpanded && filter.student && (
                    <div style={{ marginLeft: '20px', marginBottom: '4px' }}>
                      <div>
                        {classOptions.map((className) => (
                          <div key={className}>
                            <Checkbox
                              color="neutral"
                              disabled={false}
                              label={className}
                              size="md"
                              variant="soft"
                              checked={selectedStudentClasses.includes(className)}
                              onChange={() => handleStudentClassChange(className)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <Checkbox
                    color="neutral"
                    disabled={false}
                    label="Professional"
                    size="md"
                    variant="soft"
                    checked={filter?.professional}
                    onChange={(e) => {
                      setFilter({ ...filter, professional: e.target.checked });
                      setPage(1);
                    }}
                  />
                  <Divider className="my-2" />

                  <Select
                    // action={action}
                    value={location}
                    placeholder="Location"
                    className="mb-2"
                    onChange={(e, newValue) => setLocation(newValue)}
                    {...(location && {
                      // display the button and remove select indicator
                      // when user has selected a value
                      endDecorator: (
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onMouseDown={(event) => {
                            // don't open the popup when clicking on this button
                            event.stopPropagation();
                          }}
                          onClick={() => {
                            setLocation(null);
                            action.current?.focusVisible();
                          }}
                        >
                          <CloseRounded />
                        </IconButton>
                      ),
                      indicator: null,
                    })}
                    sx={{ minWidth: 160 }}
                  >
                    {locations?.map((loc, index) => (
                      <Option key={index} value={loc}>
                        {loc}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    value={selectedSubject} // Update to selectedSubject for handling subject selection
                    placeholder="Select a Subject"
                    className="mb-2"
                    onChange={(e, newValue) => {
                      setSelectedSubject(newValue);
                    }}
                    {...(selectedSubject && {
                      // display the button and remove select indicator when user has selected a subject
                      endDecorator: (
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onMouseDown={(event) => {
                            // don't open the popup when clicking on this button
                            event.stopPropagation();
                          }}
                          onClick={() => {
                            setSelectedSubject(null); // Clear the selected subject
                          }}
                        >
                          <CloseRounded />
                        </IconButton>
                      ),
                      indicator: null,
                    })}
                    sx={{ minWidth: 160 }}
                  >
                    {/* Map over Subjects.AcademicDisciplines for dropdown options */}
                    {Subjects?.AcademicDisciplines?.map((subject, index) => (
                      <Option key={index} value={subject}>
                        {subject.Category}
                      </Option>
                    ))}
                  </Select>


                  <Select
                    // action={action}
                    value={expertise}
                    placeholder="Expertise"
                    className="mb-2"
                    onChange={(e, newValue) => {
                      setExpertise(newValue);
                      setPage(1);
                    }}
                    {...(expertise && {
                      // display the button and remove select indicator
                      // when user has selected a value
                      endDecorator: (
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onMouseDown={(event) => {
                            // don't open the popup when clicking on this button
                            event.stopPropagation();
                          }}
                          onClick={() => {
                            setExpertise(null);
                            action.current?.focusVisible();
                          }}
                        >
                          <CloseRounded />
                        </IconButton>
                      ),
                      indicator: null,
                    })}
                    sx={{ minWidth: 160 }}
                  >
                    {expertises?.map((expertise, index) => (
                      <Option key={index} value={expertise}>
                        {expertise}
                      </Option>
                    ))}
                  </Select>

                  <Select
                    action={action}
                    value={rating}
                    placeholder="Rating"
                    className="mb-2"
                    onChange={(e, newValue) => {
                      setRating(newValue);
                      setPage(1);
                    }}
                    {...(rating && {
                      // display the button and remove select indicator
                      // when user has selected a value
                      endDecorator: (
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onMouseDown={(event) => {
                            // don't open the popup when clicking on this button
                            event.stopPropagation();
                          }}
                          onClick={() => {
                            setRating(null);
                            action.current?.focusVisible();
                          }}
                        >
                          <CloseRounded />
                        </IconButton>
                      ),
                      indicator: null,
                    })}
                    sx={{ minWidth: 160 }}
                  >
                    <Option value="1">
                      <Rating
                        name="text-feedback"
                        value={1}
                        readOnly
                        precision={1}
                        icon={<StarRounded fontSize="inherit" />}
                        emptyIcon={
                          <StarRounded
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      <Box sx={{ ml: 2 }}>{labels[1]}</Box>
                    </Option>
                    <Option value="2">
                      <Rating
                        name="text-feedback"
                        value={2}
                        readOnly
                        precision={2}
                        icon={<StarRounded fontSize="inherit" />}
                        emptyIcon={
                          <StarRounded
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      <Box sx={{ ml: 2 }}>{labels[2]}</Box>
                    </Option>
                    <Option value="3">
                      <Rating
                        name="text-feedback"
                        value={3}
                        readOnly
                        precision={3}
                        icon={<StarRounded fontSize="inherit" />}
                        emptyIcon={
                          <StarRounded
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      <Box sx={{ ml: 2 }}>{labels[3]}</Box>
                    </Option>
                    <Option value="4">
                      <Rating
                        name="text-feedback"
                        value={4}
                        readOnly
                        precision={4}
                        icon={<StarRounded fontSize="inherit" />}
                        emptyIcon={
                          <StarRounded
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      <Box sx={{ ml: 2 }}>{labels[4]}</Box>
                    </Option>
                    <Option value="5">
                      <Rating
                        name="text-feedback"
                        value={5}
                        readOnly
                        precision={5}
                        icon={<StarRounded fontSize="inherit" />}
                        emptyIcon={
                          <StarRounded
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      <Box sx={{ ml: 2 }}>{labels[5]}</Box>
                    </Option>
                  </Select>
                </FormGroup>
              </div>
            )}
            <SuggestedExperts className="mb-3" heading="Gyani's near you" />
          </div>
          <div className="col-12 col-lg-9">
          {windowWidth >= 992 && (
            <FormControl variant="outlined" fullwidth="true" className="mb-3 rounded ">
              <Input
                color="plain"
                id="search"
                type="text"
                className="rounded-4 px-3 shadow"
                name="search"
                size="lg"
                onChange={(e) => {
                  debouncedResults(e);
                  setPage(1);
                }}
                endDecorator={
                  <InputAdornment position="end">
                    <IconButton
                      variant="plain"
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setHeading(`Search Results`);
                        setPage(1);
                        getUsers();
                      }}
                      edge="end"
                    >
                      <SearchTwoTone />
                    </IconButton>
                  </InputAdornment>
                }
                // label="Password"
                placeholder="Search user by name or expertise or location"
              />
            </FormControl>
          )}
            <div className="row p-2 ml-1 justify-content-between my-3 ">
              <h4 className="">
                {heading}{" "}
                {users?.length && totalUsersRole ? (
                  totalUsersRole.student === 0 && totalUsersRole.teacher === 0 && totalUsersRole.professional === 0
                    ? `(${users?.length}/${users[0].total_users})`
                    : `(${users?.length}/${totalUsersRole.student + totalUsersRole.teacher + totalUsersRole.professional})`
                ) : (
                  `Searching...` // or some other placeholder text
                )}
              </h4>
            </div>

            <div className="row">
              {loading ? (
                <>
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                </>
              ) : users?.length ? (
                users
                  .filter((user, index, self) => self.findIndex((u) => u.id === user.id) === index)
                  .map((user, index) => {
                    return (
                      <UserTicket key={index} user={user} index={index} />
                    );
                  })
              ) : (
                <div className="rouded-4 p-4">
                  <h3>No user Found</h3>
                </div>
              )}
              {loadMore ? (
                <>
                  <UsersSkeleton />
                  <UsersSkeleton />
                  <UsersSkeleton />
                </>
              ) : null}
            </div>
            {showLoadButton && (
              <Button
                variant="outlined"
                disabled={loadMore}
                fullWidth
                size="md"
                className="rounded-3 shadow mt-3"
                onClick={() => {
                  loadMoreUsers(page + 1);
                }}
              >
                {loadMore ? "Loading..." : "Load More"}
              </Button>
            )}
          </div>
        </div >
      </div >
    </>
  );
};

export default UsersContainer;
