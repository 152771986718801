import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  TextField,
} from "@mui/material";
import { Avatar, CircularProgress } from "@mui/joy";
import { Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useGlobalContext } from "global/context";
import { Link, useNavigate } from "react-router-dom";

const fontFamily = "'Quicksand', sans-serif";

export const JobApplicationList = ({
  filteredapplicants,
  handleSearch,
  searchTerm,
}) => {
  return (
    <Row>
      <Col>
        {/* input search box */}
        <TextField
          label="Search..."
          variant="outlined"
          placeholder="Search by name and job"
          fullWidth
          value={searchTerm}
          onChange={handleSearch}
          sx={{ my: 2 }}
        />
        {/* table */}
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b98d4" }}>
              <TableRow>
                <TableCell sx={{ color: "#fff", fontFamily: fontFamily }}>
                  Name
                </TableCell>
                <TableCell sx={{ color: "#fff", fontFamily: fontFamily }}>
                  Applications
                </TableCell>
                <TableCell sx={{ color: "#fff", fontFamily: fontFamily }}>
                  Applied on
                </TableCell>
                <TableCell sx={{ color: "#fff", fontFamily: fontFamily }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredapplicants &&
                filteredapplicants?.map((job, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {job?.users?.first_name} {job?.users?.last_name}
                    </TableCell>
                    <TableCell>{job?.job_descriptions?.job_title}</TableCell>
                    <TableCell>{job?.createdAt?.slice(0, 10)}</TableCell>
                    <TableCell>{job?.status}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
};

export const DepartmentList = ({
  departments,
  teachersData,
  setSelectedDepartment,
  selectedDepartment,
}) => {
  const handleClick = (department) => {
    if (selectedDepartment.department?.id === department.id) {
      setSelectedDepartment({
        department: null,
        teachers: null,
      });
      return;
    }
    setSelectedDepartment({
      department: department,
      teachers: teachersData[department.id],
    });
  };

  return (
    <Row>
      <Col>
        <Paper sx={{ padding: 2 }}>
          <List>
            {departments.map((department, index) => (
              <ListItem key={department.id}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <strong
                      className="text-capitalize"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(department)}
                    >
                      {index + 1}) {department.name} department
                    </strong>
                  </Grid>
                  {teachersData[department.id] &&
                    teachersData[department.id].length > 0 ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        <strong>
                          {teachersData[department.id].length} Teachers
                        </strong>
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        No Teachers found in this department
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Col>
    </Row>
  );
};

export const NewApplications = ({ applicant }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredApplicants, setFilteredApplicants] = useState(applicant);
  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filteredApplicants = applicant.filter((applicant) => {
      const applicantTitle =
        applicant.job_descriptions.job_title?.toLowerCase();
      const applicantName =
        applicant.users.first_name?.toLowerCase() +
        " " +
        applicant.users.last_name?.toLowerCase();
      return (
        applicantName.includes(searchValue) ||
        applicantTitle.includes(searchValue)
      );
    });
    setFilteredApplicants(filteredApplicants);
  };
  if (!applicant || applicant?.length === 0) {
    return (
      <>
        <ListItem>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                No New Applications Found
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      </>
    );
  }
  return (
    <Box marginY={2}>
      <Typography variant="p" sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
        New Applications
      </Typography>
      <TextField
        label="Search..."
        variant="outlined"
        placeholder="Search by name..."
        fullWidth
        value={searchTerm}
        onChange={handleSearch}
        sx={{ my: 2 }}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#1b98d4" }}>
            <TableRow>
              <TableCell
                sx={{ color: "white", fontWeight: "bold", fontSize: "1rem" }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold", fontSize: "1rem" }}
              >
                Applications
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold", fontSize: "1rem" }}
              >
                Applied on
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredApplicants?.map((applicant, index) => (
              <TableRow key={index}>
                <TableCell>
                  {applicant?.users?.first_name}
                  {applicant?.users?.last_name}
                </TableCell>
                <TableCell>
                  <Typography key={index} variant="body2" color="textSecondary">
                    {applicant?.job_descriptions?.job_title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography key={index} variant="body2" color="textSecondary">
                    {applicant?.createdAt?.slice(0, 10)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const SelectedDepartment = ({ department, teachers }) => {
  return (
    <Box marginY={2}>
      <Typography variant="p" sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
        {department?.name} department
      </Typography>
      <Paper elevation={3} sx={{ padding: 1 }}>
        <TableContainer>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#1b98d4" }}>
                <TableCell sx={{ color: "#fff" }}>Name</TableCell>
                <TableCell sx={{ color: "#fff" }}>Designation</TableCell>
                <TableCell sx={{ color: "#fff" }}>Specialization</TableCell>
              </TableRow>{" "}
            </TableHead>
            <TableBody>
              {teachers &&
                teachers.map((teacher) => (
                  <TableRow>
                    <TableCell>{teacher.name}</TableCell>
                    <TableCell>{teacher.designation}</TableCell>
                    <TableCell>{teacher.specialization}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export const AllEvents = ({ eventData }) => {
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState([]);

  console.log("Event : ", eventData)

  const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Math.ceil((end - start) / (1000 * 60 * 60 * 24) + 1); // Difference in days
  };

  // Add daysDifference to each event
  const eventsWithDaysDifference = eventData.map((event) => ({
    ...event,
    daysDifference: calculateDaysDifference(event.eventStartDate, event.eventEndDate),
  }));

  const handleRegisteredUsersClick = (users) => {
    setSelectedUsers(users);
  };

  return (
    <div className="mb-3">
      <Row>
        <Col md={8}>
          <TableContainer className="shadow mb-3 md:mb-0">
            <Table>
              <TableHead sx={{ backgroundColor: "#1b98d4" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff", fontFamily: "Arial, sans-serif" }}>
                    Program
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontFamily: "Arial, sans-serif" }}>
                    Registered User
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontFamily: "Arial, sans-serif" }}>
                    Location
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontFamily: "Arial, sans-serif" }}>
                    Duration
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventsWithDaysDifference && eventsWithDaysDifference.length > 0 ? (
                  eventsWithDaysDifference.map((event, index) => (
                    <TableRow key={index}>
                      <TableCell
                        onClick={() => navigate(`/event/${event.id}`)}
                        style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                      >
                        {event?.title}
                      </TableCell>
                      <TableCell
                        onClick={() => handleRegisteredUsersClick(event?.registrations)}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        {event?.registrations.length}</TableCell>
                      <TableCell>{event?.location}</TableCell>
                      <TableCell>{event.daysDifference} Days</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No Events Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
        <Col md={4}>
          <div className="text-dark shadow rounded-3 p-4">
            <Typography
              variant="plain"
              color="warning"
              className="mb-3 pl-3"
            >
              <strong>Registered User List </strong>
            </Typography>
            {selectedUsers.length > 0 && (

              <div className="d-flex mb-2 p-2 hover-bg rounded-3 border-bottom">
                {selectedUsers.map((user, index) => (
                  <div className="d-flex align-items-center mb-3" key={index}>
                    <Avatar
                      alt={user?.firstName || "New User"}
                      src={user?.profile} // Replace with user's avatar URL if available
                      className="me-3"
                    />
                    <Link
                      to={`/${user?.username}`} // Replace with user's profile link if available
                      className="col"
                      style={{
                        color: "grey",
                        textDecoration: "none",
                      }}
                    >
                      <p className="cursor-pointer fw-bold text-info mb-1">
                        {`${user?.firstName || ""} ${user?.lastName || ""}`}
                      </p>
                      <p className="fs-14 lh-1">{user?.email}</p>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

