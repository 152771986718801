import React, { useState, useEffect } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalOverflow from '@mui/joy/ModalOverflow';
import Typography from '@mui/joy/Typography';
import Answers from '../Answers/Answers';
import { useGlobalContext } from 'global/context';
import NotifyAnswerModal from '../NotifyAnswer/NotifyAnswerModal';


export default function NotificationAnswer({ id, question_id, setAnswerLength, data, showAnswer, setShowAnswer }) {
    const [layout, setLayout] = useState('center');
    const { userData, api } = useGlobalContext();
    const [answers, setAnswers] = useState([]);
    const [showWriteAnswerBox, setShowWriteAnswerBox] = useState(true);

    const toggleWriteAnswerBox = () => setShowWriteAnswerBox(prev => !prev);

    const handleClose = () => {
        setLayout(null)
        setShowAnswer(false)
    }

    const getAnswers = async () => {
        try {
            const res = await api.get(`/app/answer?id=${data?.content_id}`);
            // console.log("Response Data:", res?.data); // Confirm API response
            if (res.status === 200 && res?.data?.results) {
                setAnswers(res.data.results);
                setAnswerLength(res.data.results.length);
            }
        } catch (error) {
            console.error("Error fetching answers:", error);
        }
    };

    useEffect(() => {
        getAnswers();
    }, []);

    return (
        <Modal
            open={Boolean(layout)}
            onClose={() => setLayout(null)}
        >
            <ModalOverflow>
                <ModalDialog layout={layout}>
                    <ModalClose onClick={handleClose} />
                    <Typography component="h2">Answers ({answers.length})</Typography>

                    {answers.length > 0 ? (
                        answers.map(answer => {
                            {/* console.log("Rendering Answer:", answer); // Log each answer to confirm details */}

                            return (
                                <NotifyAnswerModal
                                    key={answer.id}
                                    setLayout={setLayout}
                                    answerData={answer}
                                    questionAuthorId={userData?.id}
                                    showAnswer={showAnswer}
                                    setShowAnswer={setShowAnswer}
                                />
                            );
                        })
                    ) : (
                        <Typography>Please wait a few seconds ...</Typography>
                    )}
                </ModalDialog>
            </ModalOverflow>
        </Modal>
    );
}
