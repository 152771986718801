import { Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import UserSidebar from './UserSideBar';
import UserMainChat from './UserMainChat';
import { useGlobalContext } from "global/context";
import io from 'socket.io-client';
import styled from 'styled-components';

const PATH = process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"

const StyledMessagingContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 85vh; /* Adjust this height based on your layout needs */
  overflow-y: hidden; 

  @media (max-width: 768px) {
    flex-direction: column;
   
    height:94vh;/* Adjust based on footer height */
  }

  @media (max-width: 600px) {
    height:94vh;/* Adjust based on footer height */
  }
`;


const UserMessaging = () => {
  const { userData, api, notification } = useGlobalContext();
  const socketRef = useRef();
  const [profileData, setProfileData] = useState([]);
  const [isConnect, setIsConnect] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [allMsg, setAllMsg] = useState([]);
  const [roomData, setRoomData] = useState("");
  const [sender, setSender] = useState([]);
  const [msg, setMsg] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);  // Detect mobile view
  const [showChat, setShowChat] = useState(!isMobile);  // Default to showing chat if on mobile
  const [allMessage, setAllMessage] = useState([])

  useEffect(() => {
    // Handle window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // console.log("Sender : ",roomData)
  console.log("Socket Reference: ", socketRef.current);
  const handleSendMsg = async (message) => {
    console.log("message reply : ", message);

    if (socketRef.current && socketRef.current.connected) {
      const receiverId = roomData?.user?.institute?.id || roomData?.user?.id; // Prioritize institute ID, then user ID

      if (!receiverId) {
        console.error("Receiver ID is missing, message cannot be sent.");
        return;
      }
      console.log("rommData : ",roomData)
      const receiverRole = roomData?.user?.institute?.id ? 'institute' : roomData?.user?.role; // Determine role based on ID

      const data = {
        message,
        receiver: receiverId,
        sender: userData.id,
        room: receiverId + userData.id, // Ensure room ID is valid
        sender_role: userData.role,
        receiver_role: receiverRole,
      };

      console.log("Data hai ye : ", data);

      socketRef.current.emit("SEND_MSG", data);
      setAllMsg(prevState => [...prevState, data]);
    } else {
      console.log("Socket not connected, unable to send message.");
    }
  };


  const getAllMessages = async () => {
    try {
      const res = await api.get(`app/message/byuser/${userData?.id}`);

      setAllMessage(res.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchProfileData = async (id) => {
    try {
      let res;
      if (userData.role !== 'institute') {
        res = await api.get(`/app/user/${userData?.id}`);
        if (res.status === 200) {
          setProfileData(res.data);
        }
      } else {
        res = await api.get(`/app/institutes/${id}`);
        if (res.status === 200) {
          setProfileData(res.data.institute);
        }
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  const fetchSenderProfiles = async () => {
    try {
      const uniqueSenders = Array.from(new Set(
        allMessage.map(msg => msg.sender_user_id || msg.sender_institute_id) // Get unique sender IDs
      ));

      const senderProfiles = await Promise.all(
        uniqueSenders.map(async (senderId) => {
          // Find a message to determine the corresponding sender role
          const senderMessage = allMessage.find(msg => msg.sender_user_id === senderId || msg.sender_institute_id === senderId);
          const senderRole = senderMessage
            ? (senderMessage.sender_user_id ? 'user' : 'institute')
            : null; // Determine the sender's role

          let res;
          if (senderRole === 'institute') {
            res = await api.get(`/app/institutes/${senderId}`);
          } else if (senderRole === 'user') {
            res = await api.get(`/app/user/${senderId}`);
          }

          return res?.status === 200 ? res.data : null;
        })
      );

      // console.log("Sender Profiles: ", senderProfiles);
      setSender(senderProfiles.filter(profile => profile !== null && (profile.id || profile.institute.id) !== userData?.id));
    } catch (error) {
      console.log('Error fetching sender profiles:', error);
    }
  };
  console.log("Sender : ", sender)
  const handleReply = (e) => {
    e.preventDefault();
    if (msg) {
      // console.log("Message :", msg)
      handleSendMsg(msg);
      setMsg("");
    }
  };

  useEffect(() => {
    if (allMessage && allMessage.length > 0) {
      const uniqueSenders = allMessage.reduce((acc, allMessage) => {
        if (!acc.some(item => item.sender === allMessage.sender)) {
          acc.push(allMessage);
        }
        return acc;
      }, []);
      // console.log("UniQue Sender : ",uniqueSenders)
      fetchSenderProfiles(uniqueSenders);
    }
  }, [allMessage]);

  useEffect(() => {
    fetchProfileData(userData?.id);
    getAllMessages();

    const socket = io.connect(PATH);
    socketRef.current = socket;

    socket.on('connect', () => {
      setIsConnect(true);
      console.log("Socket connected");
    });

    socket.on('disconnect', () => {
      setIsConnect(false);
      console.log("Socket disconnected");
    });

    // Cleanup on component unmount
    return () => {
      socket.disconnect();
      console.log("Socket disconnected on cleanup");
    };
  }, [userData?.id]);

  useEffect(() => {
    if (isConnect) {
      socketRef.current.emit('ADD_USER', userData);
      socketRef.current.on('USER_ADDED', (data) => {
        console.log("User added:", data);
        setOnlineUsers(data);
      });

      socketRef.current.on("RECIVED_MSG", (data) => {
        if (data?.room === roomData?.roomId) {
          console.log("Message received for the current room:", data);
          setAllMsg(prevState => [...prevState, data]);
        } else {
          console.log('Message received for a different room:', data);
        }
      });

      // Cleanup specific listeners when component unmounts or dependencies change
      return () => {
        socketRef.current.off('USER_ADDED');
        socketRef.current.off('RECIVED_MSG');
        console.log("Listeners removed");
      };
    }
  }, [isConnect, roomData.roomId, userData]); // userData added to the dependency list in case it changes


  const handleUserClick = (user) => {
    setShowChat(true);
  };

  const handleBackToSidebar = () => {
    setShowChat(false);
  };

  if (!userData) return null;

  return (
    <StyledMessagingContainer className={!isMobile ? 'detailed-view p-1 m-3 shadow-sm rounded-3 border border-success rounded' : ''}>
      <Paper square elevation={0} sx={{ display: "flex", flexGrow: 1, overflow: 'hidden' }}>
        {/* Conditional rendering based on screen size and chat view state */}
        {isMobile ? (
          showChat ? (
            <UserMainChat
              sender={sender}
              pd={profileData}
              roomData={roomData}
              handleSendMsg={handleSendMsg}
              allMsg={allMsg}
              handleReply={handleReply}
              msg={msg}
              setMsg={setMsg}
              onBack={handleBackToSidebar}
            />
          ) : (
            <UserSidebar
              sender={sender}
              notification={notification}
              pd={profileData}
              onlineUsers={onlineUsers}
              roomData={roomData}
              setRoomData={setRoomData}
              setAllMsg={setAllMsg}
              onUserClick={handleUserClick}
            />
          )
        ) : (
          <>
            <UserSidebar
              sender={sender}
              notification={notification}
              pd={profileData}
              onlineUsers={onlineUsers}
              roomData={roomData}
              setRoomData={setRoomData}
              setAllMsg={setAllMsg}
              onUserClick={handleUserClick}
            />
            <UserMainChat
              sender={sender}
              pd={profileData}
              roomData={roomData}
              handleSendMsg={handleSendMsg}
              allMsg={allMsg}
              handleReply={handleReply}
              msg={msg}
              setMsg={setMsg}
            />
          </>
        )}
      </Paper>
    </StyledMessagingContainer>
  );
};

export default UserMessaging;