import React, { useState } from 'react';
import { useGlobalContext } from "global/context";
import styled from 'styled-components';
import Swal from 'sweetalert2';

const PageBorder = styled.div`
  position: relative;
  width: 95%;
  height: 90vh;
  border: 5px solid black; /* Outer border */
  border-radius: 20px;
  box-sizing: border-box;
  margin: 30px;
  padding: 20px; /* Space for the inner border */
  overflow: hidden;

  /* Add the background image */
  background-image: url('https://static.vecteezy.com/system/resources/thumbnails/013/215/583/small/abstract-background-with-overlap-layer-and-dynamic-shadow-on-background-background-for-wallpaper-banner-background-eps-10-vector.jpg');
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;

  /* Inner Border */
  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 3px solid black;
    border-radius: 15px;
    z-index: 1;
  }

  /* Watermark Text */
  &::after {
    content: '${(props) => props.watermark || "Default Watermark"}';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 50px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.1); /* Light gray watermark */
    white-space: nowrap;
    z-index: 0; /* Ensure it appears behind stars and content */
    pointer-events: none;
  }

  .star {
    position: absolute;
    width: 15px;
    height: 15px;
    background: black;
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
    z-index: 2; /* Ensure stars appear above inner border */
  }

  /* Position stars between the outer and inner borders */
  .star-top-left {
    top: 15px;
    left: 15px;
  }

  .star-top-right {
    top: 15px;
    right: 15px;
  }

  .star-bottom-left {
    bottom: 15px;
    left: 15px;
  }

  .star-bottom-right {
    bottom: 15px;
    right: 15px;
  }

  .content {
    position: relative;
    z-index: 2;
    overflow-y: auto; /* Allow scrolling for overflow content */
    height: 100%; /* Take up all available height */
    padding: 10px 20px; /* Adjust padding for content */
    box-sizing: border-box;
  }

  .details {
    margin-bottom: 20px;
  }

  .programs {
    font-weight: bold;
    margin-top: 10px;
  }

  .program-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .program-list li {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
  }

  .program-list div {
    flex: 1;
  }
`;


const EventPage = ({ data }) => {
  const { api, userData } = useGlobalContext();
  const [isRegistering, setIsRegistering] = useState(false);

  // Calculate days difference
  const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Math.ceil((end - start) / (1000 * 60 * 60 * 24) + 1); // Difference in days
  };

  const daysDifference = calculateDaysDifference(
    data?.eventStartDate,
    data?.eventEndDate
  );

  const handleRegister = async () => {
    if (!userData.id || !data?.id) {
      alert("User or event information is missing.");
      return;
    }
  
    // Show confirmation dialog
    const confirmRegistration = await Swal.fire({
      title: 'Are you sure?',
      width: 400,
      text: "Do you want to register for this event?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, register me!',
      cancelButtonText: 'Cancel',
    });
  
    if (confirmRegistration.isConfirmed) {
      setIsRegistering(true);
      try {
        const response = await api.post('app/events/registerUser', {
          userId: userData.id,       // User ID
          eventId: data.id,          // Event ID
          email: userData.email,     // User Email
          firstName: userData.first_name, // User First Name
          lastName: userData.last_name,   // User Last Name
          phone: userData.phone,     // User Phone
          profile: userData.profile, // User Profile
          role: userData.role        // User Role
        });
  
        if (response.status === 201) {
          // Success: Show a SweetAlert message
          Swal.fire({
            icon: 'success',
            width: 400,
            title: 'Success!',
            text: response.data.message, // Show the message returned from the server
            confirmButtonText: 'OK',
          });
        }
      } catch (error) {
        console.error("Error registering for the event:", error);
  
        if (error.response && error.response.status === 400) {
          // Show SweetAlert for already registered user
          Swal.fire({
            icon: 'warning',
            width: 400,
            title: 'Already Registered',
            text: error.response.data.message, // Show the message returned from the server
            confirmButtonText: 'OK',
          });
        } else {
          // Generic error handling for other status codes
          Swal.fire({
            icon: 'error',
            width: 400,
            title: 'Oops...',
            text: error.response?.data?.message || "An error occurred. Please try again.",
            confirmButtonText: 'OK',
          });
        }
      } finally {
        setIsRegistering(false); // Re-enable the button after the request is done (either success or failure)
      }
    }
  };
  
  // console.log("Data : ", data)

  return (
    <div className="rounded-lg mb-3">
      {/* Banner Content */}
      <div className="bg-white text-purple-900 p-1 mx-auto shadow-lg w-full max-w-lg">
        <h1
          className="text-2xl font-bold text-center mb-2 relative text-purple-900
          transition-transform transform hover:translate-y-[-5px] hover:rotate-x-[10deg] hover:rotate-y-[10deg]"
          style={{
            textShadow: '1px 1px 0 #a8a8a8, 2px 2px 0 #a8a8a8, 3px 3px 0 #a8a8a8, 4px 4px 0 #a8a8a8'
          }}
        >
          {data?.instituteName}
        </h1>
      </div>
      {/* Purple Left Part */}
      <div className="relative w-full max-w-lg mx-auto shadow-lg overflow-visible">
        <div className="bg-purple-900 text-white p-6 w-full h-full">
          <div className="mb-6">
            <p className="text-center  text-3xl font-bold text-violet-200">
              {daysDifference}{" "}
              days Program of
            </p>
            <h1 className="text-4xl font-bold text-yellow-400 text-center mb-2">
              {data?.title}
            </h1>
            <p className="text-center text-sm text-yellow-400 mb-4">
              Location : {data?.location}
            </p>
          </div>
          <div className=' text-yellow-400'>
            <h1 className='font-bold text-yellow-400'>CORDINATOR</h1>
            <div className="flex justify-between items-center">
              <div>{data?.coordinatorName}</div>
              <div>{data?.contactNumber}</div>
              <div>{data?.email}</div>
            </div>
          </div>
        </div>
      </div>
      {/* Details Section */}
      <div className="bg-yellow-400 text-black p-6 mx-auto shadow-lg w-full max-w-lg">
        <h2 className="text-xl font-bold mb-2">DETAILS</h2>
        <ul className="list-disc pl-5 text-sm">
          <p>{data?.description}</p>
        </ul>
      </div>
      <div className="bg-yellow-400 text-black p-6 mx-auto shadow-lg w-full max-w-lg">
        <h2 className="text-xl font-bold mb-2">PROGRAMS</h2>
        <ul className="space-y-2 list-disc pl-4">
          {data.programs.map((pro, index) => (
            <li
              key={index}
              className="flex justify-between items-center text-sm"
            >
              <span className="flex-1">{pro.program}</span>
              <span className="flex-1 text-center">{pro.date}</span>
              <span className="flex-1 text-right">{pro.startTime}</span>
              <span className="flex-1 text-center">{pro.endTime}</span>
            </li>
          ))}
        </ul>

      </div>
      {/* Registration Section */}
      <div className="bg-yellow-400 text-black p-6 mx-auto shadow-lg w-full max-w-lg">
        <div className="flex items-center justify-between">
          {/* Registration Link Text */}
          <p className="text-sm">
            Register at:{" "}
            <a
              href={data?.registrationLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              {data?.registrationLink}
            </a>
          </p>
          {/* Register Button */}
          {data?.institute_id !== userData?.id && (
  <div
    target="_blank"
    rel="noopener noreferrer"
    className="bg-purple-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-lg hover:shadow-none transform hover:translate-y-1 active:translate-y-1 border-b-4 border-purple-800 cursor-pointer"
    onClick={handleRegister}
  >
    REGISTER
  </div>
)}
        </div>
      </div>
      {/* Footer Section */}
      <div className="bg-purple-900 text-yellow-400 p-6 mx-auto shadow-lg w-full max-w-lg">
        {/* Check if award exists and is not empty */}
        {data?.award && data?.award !== "" && (
          <p className="font-bold">
            {data?.award} will be provided
          </p>
        )}
        {/* Check if fees is not 0.00 */}
        {parseFloat(data?.fees) > 0 && (
          <p className="font-bold">
            REGISTRATION FEES : {data?.fees} RS
          </p>
        )}
      </div>
      <footer className="bg-yellow-400 text-purple-900 font-bold text-center p-1 mx-auto shadow-lg w-full max-w-lg">
        Chief Guest : {data?.chiefGuest}
      </footer>
    </div>

  );
};

export default EventPage;
