import React from "react";
import { useState, useEffect } from "react";
import Expert from '@mui/icons-material/Diversity1';
import { useGlobalContext } from 'global/context';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import MobileJobCard from "./MobileJobCard";
import { Link } from 'react-router-dom';

const GetStartedMobile = () => {

    const { api } = useGlobalContext();
    const [jobs, setJobs] = useState([]);

    const sliderLeft = () => {
        var slider = document.getElementById('slider')
        slider.scrollLeft = slider.scrollLeft - 400
    }

    const sliderRight = () => {
        var slider = document.getElementById('slider')
        slider.scrollLeft = slider.scrollLeft + 400
    }

    const getJobs = async () => {
        try {
            const res = await api.get(`/app/jobs/allJobs`);
            if (res?.status === 200) {
                console.log("Job was successfully Fetchech: ", res?.data?.results);
                setJobs(res?.data?.results);
            }
        } catch (err) {
            console.log("Error getting jobs", err);
        }
    }

    useEffect(() => {
        getJobs();
    }, [api]);

    return (
        <section className="get-started-area pt-4 pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <div
                            className="align-items-center justify-center shadow rounded-4 p-3 fixed-size-container"
                            style={{
                                backgroundColor: "rgba(248, 249, 250, 0.8)",
                                textAlign: "center",
                                borderRadius: "15px",
                                transition: "background-color 0.3s ease, transform 0.3s ease",
                                cursor: "pointer",
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = "rgba(233, 236, 239, 0.8)"; // Semi-transparent hover color
                                e.currentTarget.style.transform = "scale(1.05)";
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = "rgba(248, 249, 250, 0.8)";
                                e.currentTarget.style.transform = "scale(1)";
                            }}
                        >
                            <div className="mx-4">
                                <svg
                                    aria-hidden="true"
                                    className="fixed-size-icon svg-icon-color-1"
                                    viewBox="0 0 48 48"
                                >
                                    <path
                                        opacity=".2"
                                        d="M29.22 38.1a3.4 3.4 0 014.81-4.82l8.81 8.81a3.4 3.4 0 01-4.81 4.81l-8.81-8.8z"
                                    />
                                    <path d="M18.5 5a1 1 0 100 2c.63 0 1.24.05 1.84.15a1 1 0 00.32-1.98A13.6 13.6 0 0018.5 5zm7.02 1.97a1 1 0 10-1.04 1.7 11.5 11.5 0 015.44 8.45 1 1 0 001.98-.24 13.5 13.5 0 00-6.38-9.91zM18.5 0a18.5 18.5 0 1010.76 33.55c.16.57.46 1.12.9 1.57L40 44.94A3.5 3.5 0 1044.94 40l-9.82-9.82c-.45-.45-1-.75-1.57-.9A18.5 18.5 0 0018.5 0zM2 18.5a16.5 16.5 0 1133 0 16.5 16.5 0 01-33 0zm29.58 15.2a1.5 1.5 0 112.12-2.12l9.83 9.83a1.5 1.5 0 11-2.12 2.12l-9.83-9.83z" />
                                </svg>
                            </div>
                            <div className="media-body" style={{ textAlign: "center" }}>
                                <p className="pb-2 fs-17 fixed-size-heading" style={{ color: "#333", margin: "4px" }}>
                                    ASK QUESTIONS
                                </p>
                                <a href="/questions" className="fixed-size-link d-inline-flex align-items-center justify-center" style={{ color: "#007bff", textDecoration: "none"}}>
                                    Browse questions <i className="la la-arrow-right icon" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div
                            className="align-items-center justify-center shadow rounded-4 p-3 fixed-size-container"
                            style={{
                                backgroundColor: "rgba(248, 249, 250, 0.8)",
                                textAlign: "center",
                                borderRadius: "15px",
                                transition: "background-color 0.3s ease, transform 0.3s ease",
                                cursor: "pointer",
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = "rgba(233, 236, 239, 0.8)"; // Semi-transparent hover color
                                e.currentTarget.style.transform = "scale(1.05)";
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = "rgba(248, 249, 250, 0.8)";
                                e.currentTarget.style.transform = "scale(1)";
                            }}
                        >
                            <div className="mx-4  fixed-size-icon">
                                <svg
                                   aria-hidden="true"
                                    className="fixed-size-icon svg-icon-color-1"
                                    viewBox="0 0 48 48"
                                >
                                    <Expert />
                                </svg>
                            </div>
                            <div className="media-body" style={{ textAlign: "center" }}>
                                <p
                                    className="pb-3 fs-17 fixed-size-heading"
                                    style={{
                                        color: "#333",
                                        margin: "10px 0",
                                    }}
                                >
                                    EXPERTS
                                </p>
                                <a href="/users" className="fixed-size-link d-inline-flex align-items-center justify-center" style={{ color: "#007bff", textDecoration: "none" }}>
                                    Connect With Expert <i className="la la-arrow-right icon" style={{ marginLeft: "5px" }} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
  <div className='fw-bold mt-2 d-flex justify-content-between align-items-center'>
    <div>
      <h3 className='fw-bold mb-1'>Jobs</h3>
    </div>
    <Link to='/careers' className="text-decoration-none">
      View All Jobs
    </Link>
  </div>

  <div className="relative flex items-center">
    <MdChevronLeft
      className="absolute left-0 cursor-pointer bg-gray-200 rounded-full p-1 z-10"
      onClick={sliderLeft}
      size={24}
    />
    <div id="slider" className="w-full h-full overflow-x-scroll overflow-y-hidden whitespace-nowrap scroll-smooth scrollbar-hide">
      {jobs?.map((job, index) => (
        <div className="w-[200px] inline-block p-2 hover:scale-105 ease-in-out duration-300 " key={job.id}>
          <MobileJobCard jd={job} />
        </div>
      ))}
    </div>
    <MdChevronRight
      className="absolute right-0 cursor-pointer bg-gray-200 rounded-full p-1 z-10"
      onClick={sliderRight}
      size={24}
    />
  </div>
</div>

            </div>
        </section>

    );
};

export default GetStartedMobile;