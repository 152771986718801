import React, { useEffect, useState } from "react";
// import Modal from "@mui/joy/Modal";
import Textarea from "@mui/joy/Textarea";
import RadioGroup from "@mui/joy/RadioGroup";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import Radio from "@mui/joy/Radio";
import JobCard from "./JobCard";
import Button from "@mui/joy/Button";
import { useGlobalContext } from "global/context";
import WishlistJobCard from "./WishlistJobCard";

const WishlistJobs = () => {

  const{userData ,api , wishlist } = useGlobalContext();
  const[allJobs , setAllJobs ] = useState([])
  const[wishlistJobs, setWishListJobs] = useState([]);
  const [noJobsMessage, setNoJobsMessage] = useState(""); // New state to handle no jobs message


  const wishlistJob = async () => {
    
    try {
        const res = await api.get(`/app/jobs/${userData?.id}/job/wishlist`);
        if (res?.status === 200) {
          // Check if the response contains a message
          if (res.data.message) {
            setWishListJobs([]); // Set wishlistJobs to an empty array
            setNoJobsMessage(res.data.message); // Set the no jobs message
          } else {
            setWishListJobs(res.data); // Set wishlist jobs if found
            setNoJobsMessage(""); // Clear no jobs message
          }
        } else {
        console.log("No jobs were added to the wishlist.");
      }
  
    } catch (err) {
      console.log("Error fetching wishlist jobs: ", err);
    }
  };
  

  const getAllJobs = async () => {
    try {
      const res = await api.get(`/app/jobs/post`);
      if (res?.status === 200) {
        setAllJobs(res?.data?.results);
      }
    } catch (err) {
      console.log("Error getting jobs", err);
    }
  };

  useEffect(() => {
    const fetchJobsAndWishlist = async () => {
      await getAllJobs();
      await wishlistJob(); 
    };
  
    fetchJobsAndWishlist();
  }, []);
 

  return (
    <>
      <div>
        <div
          className="container mt-lg-2 mb-5 p-5 position-relative bg-light shadow rounded rounded-sm-0 "
          style={{
            backgroundImage: "linear-gradient(45deg, #472fcb, #00a4a5)",
          }}
        >
          <h1 className="fs-60 fw-bold mb-3 text-center text-white">
            {" "}
            Our Favourite Job Saved For later{" "}
          </h1>
          <h5 className="fs-25 font-weight-bold text-bold mb-3 text-center text-white">
            Our philosophy is simple - hire a team of diverse, passionate people
            <br></br>
            and foster a culture that empowers you to do your best work.{" "}
          </h5>
          
        </div>
        <div className="d-flex align-items-end justify-content-center">
          <div  className="container row row-cols-3" style={{ maxHeight: "500px", overflowY: "auto" }}>
                      {wishlistJobs?.map((job) => (
                        <div >
                          <div className="col" key={job?.id}>
                            <WishlistJobCard jd={job} getAllJobs={getAllJobs} wishlistJob={wishlistJob}/>
                          </div>
                        </div>
                      ))}
                    </div>
          </div>
      </div>
      <style>
        {`
          .container {
            scrollbar-width: thin;
            scrollbar-color: #472fcb #00a4a5;
          }

          .container::-webkit-scrollbar {
            width: 8px;
          }

          .container::-webkit-scrollbar-thumb {
            background-color: #472fcb;
            border-radius: 10px;
            border: 2px solid #00a4a5;
          }

          .container::-webkit-scrollbar-track {
            background-color: #00a4a5;
            border-radius: 10px;
          }
        `}
      </style>
    </>
  );
};

export default WishlistJobs;