import React, { useState } from "react";
import HeroContact from "./HeroContact";
import * as Yup from "yup";  // added
import { useFormik } from "formik";
import { api } from "api/api";
import { useEffect } from "react";
import { render } from "@testing-library/react";
import { Popup } from "layout/Popup";


const Contact = () => {
  const [alert, setAlert] = useState({  
    show: false,
    type: "",
    message: ""
  });
  // object created tested
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNo: "",
      message: "",
    },
    // validation
    validationSchema: Yup.object({
      name: Yup.string().required("Required").max(20, "Must be 20 characters or less").min(5, "Must be 5 characters or more"),
      email: Yup.string().email("Invalid email format").required("Required"),
      contactNo: Yup.string().required("Required").max(10, "Must be 10 characters or less").min(10, "Must be 10 characters or more"),
      message: Yup.string().required("Required").max(100, "Must be 100 characters or less").min(20, "Must be 20 characters or more")
    }),
    // onsubmit
    onSubmit: async (values) => {
      Popup({ show: true, type: "loading", message: "L" })
      console.log("values", values);
      // /*alert(JSON.stringify(formik.values))*/
      try {
        const res = await api.post("public/contactus", values);
        console.log("res", res);
        // condition and error handling
        if (res.status === 200) {
          setAlert({ show: true, type: "success", message: "Message sent successfully" });
          // render = {alert} 
          // alert("Message sent successfully") 
        }
        else { alert("Message not sent") }
      } catch (error) {
        setAlert({ show: true, type: "danger", message: "Message not sent" });
        console.log("error", error)
      }
    }
  })

  console.log("Formik", formik.errors);
  console.log("Formik", formik.values);
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert("");
      }, 5000);
    }
  }, [alert]);

  return (

    <div>

      <HeroContact />

      <section className="contact-area pt-80px pb-80px">
        <div className="container">
          <form
            onSubmit={formik.handleSubmit}
            className="contact-form card card-item"
          >
            <div className="card-body row">
              <div className="col-lg-7">
                {alert.show && (<div
                  className={`alert alert-${alert.type} d-block  contact-success-message mb-3`}
                  role="alert"
                >
                  {alert.message}
                </div>)}



                <div className="form-group">
                  <label className="fs-14 text-black fw-medium lh-20">
                    Your Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className="form-control form--control fs-14"
                    placeholder=" Please enter your  name ..."
                    errorhandle="Pl. fill this field"
                  />
                  <span className="text-danger">{formik.errors.name}</span>

                </div>
                {/* end form-group */}
                <div className="form-group">
                  <label className="fs-14 text-black fw-medium lh-20">
                    Email{" "}
                    <span className="text-gray">
                      (Address never made public)
                    </span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className="form-control form--control fs-14"
                    placeholder="Please enter your email ..."
                    errorhandle="Pl. fill this field"
                    helperfield="Pl. fill this field"

                  />
                  <span className="text-danger">{formik.errors.email}</span>
                </div>
                {/* end form-group */}
                <div className="form-group">
                  <label className="fs-14 text-black fw-medium lh-20">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone-number"
                    name="contactNo"
                    value={formik.values.contactNo}
                    onChange={formik.handleChange}
                    className="form-control form--control fs-14"
                    placeholder="Your phone number"
                  />
                  <span className="text-danger">{formik.errors.contactNo}</span>
                </div>
                {/* end form-group */}
                <div className="form-group">
                  <label className="fs-14 text-black fw-medium lh-20">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    className="form-control form--control fs-14"
                    rows={6}
                    placeholder="Tell us how we can help you."
                    defaultValue={""}
                  />
                  <span className="text-danger">{formik.errors.message}</span>
                </div>
                {/* end form-group */}
                <div className="form-group mb-0">
                  <button
                    id="send-message-btn"
                    className="btn theme-btn"
                    type="submit"
                  >
                    Send Message
                  </button>
                </div>
                {/* end form-group */}
              </div>
              {/* end col-lg-7 */}
              <div className="col-lg-5">
                <div className="contact-information-wrap pl-4 border-left border-left-gray h-100">
                  <h3 className="fs-20 pb-3 fw-bold">Contact Information</h3>
                  <div className="divider">
                    <span />
                  </div>
                  <p className="pt-3 pb-5">
                    Talk to us 
                  </p>
                  <ul className="generic-list-item fs-15">
                    <li className="mb-3">
                      <div className="icon-element icon-element-xs shadow-sm d-inline-block mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="19px"
                          viewBox="0 0 24 24"
                          width="19px"
                          fill="#3cb1c6"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z" />
                        </svg>
                      </div>{" "}
                      +91 892 900 0742
                    </li>
                    <li className="mb-3">
                      <div className="icon-element icon-element-xs shadow-sm d-inline-block mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="19px"
                          viewBox="0 0 24 24"
                          width="19px"
                          fill="#3cb1c6"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" />
                        </svg>
                      </div>
                      <a
                        href="mailto:support@eksathi.com"
                        className="d-inline-block"
                      >
                        support@eksathi.com
                      </a>
                    </li>
                    <li className="mb-3">
                      <div className="icon-element icon-element-xs shadow-sm d-inline-block mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="19px"
                          viewBox="0 0 24 24"
                          width="19px"
                          fill="#3cb1c6"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z" />
                          <circle cx={12} cy={9} r="2.5" />
                        </svg>
                      </div>{" "}
                      Jaipur, India
                    </li>
                  </ul>
                  <div className="social-icon-box pt-5">
                    <h3 className="fs-17 pb-3 fw-bold">Follow Us on</h3>
                    <a
                      className="mr-1 icon-element icon-element-sm shadow-sm text-gray hover-y d-inline-block"
                      href="https://www.facebook.com/ek.sathi.71/"
                      target="_blank"
                      title="Follow on Facebook"
                    >
                      <svg
                        focusable="false"
                        className="svg-inline--fa fa-facebook-f fa-w-10"
                        width="16px"
                        height="16px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="currentColor"
                          d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                        />
                      </svg>
                    </a>
                    <a
                      className="mr-1 icon-element icon-element-sm shadow-sm text-gray hover-y d-inline-block"
                      href="https://twitter.com/eksathi1"
                      target="_blank"
                      title="Follow on Twitter"
                    >
                      <svg
                        focusable="false"
                        className="svg-inline--fa fa-twitter fa-w-16"
                        width="16px"
                        height="16px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                        />
                      </svg>
                    </a>
                    <a
                      className="mr-1 icon-element icon-element-sm shadow-sm text-gray hover-y d-inline-block"
                      href="https://www.linkedin.com/in/eksathi/"
                      target="_blank"
                      title="Follow on Linkedin"
                    >
                      <svg
                        focusable="false"
                        className="svg-inline--fa fa-linkedin fa-w-14"
                        width="16px"
                        height="16px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                        />
                      </svg>
                    </a>
                    <a
                      className="mr-1 icon-element icon-element-sm shadow-sm text-gray hover-y d-inline-block"
                      href="https://www.instagram.com/eksathi21/"
                      target="_blank"
                      title="Follow on Instagram"
                    >
                      <svg
                        focusable="false"
                        className="svg-inline--fa fa-instagram-square fa-w-14"
                        width="16px"
                        height="16px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              {/* end col-lg-5 */}
            </div>
            {/* end row */}
          </form>
        </div>
        {/* end container */}
      </section>
    </div>

  );
};


export default Contact;
