import * as React from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import {
  Chip,
  ChipDelete,
  FormControl,
  FormLabel,
  Input,
  Option,
  Select,
} from "@mui/joy";
import { DeleteForever, FavoriteBorder } from "@mui/icons-material";
import SkillsChip from "pages/user/setting/components/SkillsChip";
import { useGlobalContext } from "global/context";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Skills from "pages/user/setting/components/Skills";

var removeByAttr = function (arr, attr, value) {
  var i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};

export default function AddSkill({
  open = false,
  setOpen,
  action,
  skills,
  userId,
  refresh,
  refreshCount,
}) {
  const [layout, setLayout] = React.useState(undefined);
  const [filteredSkills, setFilteredSkills] = useState(skills);
  const { userData, api } = useGlobalContext();
  const [skillName, setSkillName] = useState("");
  const [proficiency, setProficiency] = useState("");

  const aisiSkill = skills.map((skill) => skill.skill_name);
  const UserId = skills[0]?.user_id || userId; 

  // console.log("skills : ",skills)

   // Effect to update filteredSkills when skills change
   useEffect(() => {
    const skillNames = skills.map((skill) => skill.skill_name);
    setFilteredSkills(skillNames);
  }, [skills]); 

  const handleSkillNameChange = (event) => {
    setSkillName(event.target.value);
  };

  const addSkill = async () => {
    if (!skillName) {
      toast.error("Skill name is required!");
      return;
    }
    console.log("Before Add Skill name : ",skillName)
    if (filteredSkills.some((skill) => skill.skill_name === skillName)) {
      toast.error(`${skillName} is already in the skill list!`);
      return;
    }

    try {
      const res = await api.post(`/app/candidates/skills/${userData.id}`, {
        user_id: userId,
        skill_name: skillName,
        proficiency_level: "Expert",
      });
      if (res?.status === 200) {
        toast.success(`${skillName} successfully added!`);
        setSkillName("");
        // setOpen(false);
        refresh(++refreshCount);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const deleteSkill = async (skillId, skillName) => {
    try {
      const res = await api.post('/app/candidates/delete-skill', {
        userId: userData.id,        // Send the user ID to the backend
        skillToRemove: skillName,   // Send the skill name to be deleted
      });
  
      if (res?.status === 200) {
        // Remove the skill from the filteredSkills array by skillName (not skillId)
        const updatedSkillNames = filteredSkills[0].filter(skill => skill !== skillName);
        setFilteredSkills([updatedSkillNames]);  // Update with the filtered array
        toast.success(`${skillName} successfully deleted!`);
      }
    } catch (err) {
      console.error("Error deleting skill:", err);
      toast.error(err?.response?.data?.message);
    }
  };
  
  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={layout}
          sx={{ maxWidth: 500 }}
        >
          <ModalClose />
          <Typography id="layout-modal-title" component="h2">
            Add new skill
          </Typography>

          <FormControl className="">
            <FormLabel>Name*</FormLabel>
            <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu"></IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="write skill here..  "
                    // inputProps={{ "aria-label": "search google maps" }}
                    value={skillName}
                    onChange={(e) => setSkillName(e.target.value)}
                  />

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                    onClick={addSkill}
                  >


                    <AddIcon />
                  </IconButton>
                </Paper>
          </FormControl>

          <Stack
            direction="row"
            spacing={1}
            className="my-3 bg-light p-3 rounded flex-wrap"
            wrap
          >
            {/* <p className="fw-bold">Delete  <i class="bi bi-arrow-right"></i></p> */}
            {filteredSkills[0]?.map((skill, index) => (
    <SkillsChip
      key={index}  // Use the index as a fallback key
      id={index}  // Use index or leave this out if not needed
      name={skill}  // Use skill name directly
      userId={UserId} 
      currentUser={userData.id} // Pass user ID if needed
      deleteSkill={deleteSkill}  // Ensure deleteSkill works with skill names
    />
  ))}
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
