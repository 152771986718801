import * as React from "react";
import { useState } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import { useGlobalContext } from "global/context";
import { Popup } from "layout/Popup";
import { Badge, CardCover } from "@mui/material";
import { FaMapMarkerAlt } from 'react-icons/fa';
import {
  ConnectWithoutContactTwoTone,
  HourglassTopTwoTone,
  MailTwoTone,
  PersonAddAltSharp,
  PunchClockTwoTone,
  SmsFailedRounded,
  Star,
  StarRounded,
  StarsTwoTone,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Rating } from "@mui/material";
import moment from "moment";
import { Button } from "@mui/joy";
import { toast } from "react-hot-toast";

export default function UserTicket({ user, index }) {
  const navigate = useNavigate();
  const { userData, api, setShowMessage, setMessageTo, setAuth } =
    useGlobalContext();
  const [connectionStatus, setConnectionStatus] = useState(
    user?.connectionStatus
  );

  const getRandomRating = () => {
    const minRating = 0.5; // Minimum rating
    const maxRating = 5; // Maximum rating
    const step = 0.5; // Increment step

    // Calculate random rating in steps of 0.5
    return Math.round((Math.random() * (maxRating - minRating) + minRating) / step) * step;
  };


  const handleConnect = async () => {
    try {
      const res = await api.post(`/app/connections/send-request`, {
        senderId: userData?.id,
        receiverId: user?.id,
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        setConnectionStatus("pending"); // Update the state to 'pending'
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        setAuth(true);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleMessage = () => {
    setShowMessage(true);
    setMessageTo(user);
  };

  return (
    <>
      {/* User Card */}
      <div className="col-lg-4 responsive-column-half p-2">
        <Card
          orientation="horizontal"
          variant="outlined"
          sx={{
            overflow: "hidden",
            bgcolor: "background.body",
            "&:hover": { boxShadow: "md" },
            border: 0,
          }}
          className={`p-0 shadow hover-shadow justify-content-stretch animate slide delay-${index}`}
        >
          <div className="h-100 p-2">
            <Avatar
              src={user?.avatar_url || "images/student.webp"}
              // srcSet="https://images.unsplash.com/photo-1507833423370-a126b89d394b?auto=format&fit=crop&w=90&dpr=2 2x"
              loading="lazy"
              variant="square"
              className="rounded-2"
              sx={{ width: "100%", height: 90 }}
            />
            <div className="mt-1">
              <Button
                color={
                  connectionStatus === "accepted"
                    ? "info"
                    : connectionStatus === "pending"
                      ? "warning"
                      : connectionStatus === "rejected"
                        ? "danger"
                        : "primary"
                }
                variant="soft"
                size="sm"
                startDecorator={
                  connectionStatus === "accepted" ? (
                    <MailTwoTone />
                  ) : connectionStatus === "pending" ? (
                    <HourglassTopTwoTone />
                  ) : connectionStatus === "rejected" ? (
                    <SmsFailedRounded />
                  ) : (
                    <PersonAddAltSharp />
                  )
                }
                className="rounded-2"
                onClick={
                  connectionStatus === "accepted"
                    ? handleMessage
                    : connectionStatus === "pending"
                      ? null
                      : handleConnect
                }
              >
                {connectionStatus === "accepted"
                  ? "Message"
                  : connectionStatus === "pending"
                    ? "Requested"
                    : connectionStatus === "rejected"
                      ? "Declined"
                      : "Connect"}
              </Button>
            </div>
          </div>
          <CardContent sx={{ p: 1 }}>
            <div className="lh-sm">
              {user?.role === 'student' ? (
                // Display class level if the user is a student
                user?.classLevel ? (
                  <small className="rounded-1 border px-1 text-secondary">
                    Class : {user.classLevel}
                  </small>
                ) : (
                  <small className="rounded-1 border px-1 text-secondary">
                    Class : Not Specified
                  </small>
                )
              ) : user?.skills && typeof user.skills === 'string' && user.skills.trim() !== '' ? (
                // If the role is not 'student', display skills if available
                (() => {
                  try {
                    const skillsArray = JSON.parse(user.skills); // Parse the skills JSON string

                    // Check if there are more than two skills
                    if (skillsArray.length > 2) {
                      return (
                        <small className="">
                          {`${skillsArray[0]}, ${skillsArray[1]}, ...`}
                        </small>
                      );
                    }

                    // Render all skills if there are two or fewer
                    return (
                      <small className="rounded-1 border px-1 text-secondary">
                        {skillsArray.join(', ')}
                      </small>
                    );
                  } catch (error) {
                    console.error("Error parsing skills: ", error);
                    return <small className="rounded-1 border px-1 text-secondary">Newbee</small>;
                  }
                })()
              ) : (
                // Fallback if no skills are available
                <small className="rounded-1 border px-1 text-secondary">Newbee</small>
              )}
            </div>

            <Link
              to={`/${user?.username}`}
              className="text-info lh-1 fs-16 fw-bold text-capitalize "
            >
              {user?.first_name}
            </Link>
            <Typography className="mb-4 mt-1" style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
              <FaMapMarkerAlt className="me-1" style={{ color: 'red' }} />
              {user?.area} - {user?.city_name}
            </Typography>
            {/* <Typography className="mb-3" style={{ fontSize: "12px" }}>
              {user?.district}
            </Typography> */}
            {/* <div className="d-flex align-items-center">
              <Rating
                name="size-small"
                readOnly
                defaultValue={user?.rating}
                size="small"
                precision={0.5}
                icon={<StarRounded fontSize="inherit" />}
                emptyIcon={
                  <StarRounded style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />{" "}
            </div> */}
            <div className="d-flex align-items-center">
              <Rating
                name="size-small"
                readOnly
                defaultValue={getRandomRating()}
                size="small"
                precision={0.5}
                icon={<StarRounded fontSize="inherit" />}
                emptyIcon={
                  <StarRounded style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              {/* <span className='ml-2'> {user?.rating}</span> */}
            </div>
            {/* <Typography className={"text-secondary"} style={{ fontSize: '12px' }}> {user?.last_active ? `Last active ${moment(user?.last_active).calendar()}` : null}</Typography> */}
          </CardContent>
          {Boolean(user?.role || user?.profession) && (
            <>
              <Divider />
              <CardOverflow
                variant="soft"
                color="primary"
                sx={{
                  px: 0.2,
                  writingMode: "vertical-rl",
                  textAlign: "center",
                  fontSize: "xs2",
                  fontWeight: "xl2",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                }}
              >
                {user?.role || user?.profession}
              </CardOverflow>
            </>
          )}
        </Card>
      </div>
    </>
  );
}
