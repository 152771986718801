import React, { useEffect, useState } from "react";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import Textarea from "@mui/joy/Textarea";
import Checkbox from "@mui/joy/Checkbox";
import PaymentsIcon from "@mui/icons-material/Payments";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import RadioGroup from "@mui/joy/RadioGroup";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Apartment from "@mui/icons-material/Apartment";
import Radio from "@mui/joy/Radio";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/joy/Box";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import * as Yup from "yup";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { selectClasses } from "@mui/joy/Select";
import {
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  Typography,
  createFilterOptions,
} from "@mui/joy";
import ReactQuill from "react-quill";
import "./components/Css/Careers.css";
import { useFormik } from "formik";
import { useGlobalContext } from "global/context";
import { Link, useNavigate } from "react-router-dom";
import { CloseRounded } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { FormHelperText } from "@mui/material";
import { Container, Form } from "react-bootstrap";

const StartTime = dayjs().set("hour", 10).startOf("hour");

const endTime = dayjs().set("hour", 10).startOf("hour");
const nineAM = dayjs().set("hour", 9).startOf("hour");

const isWeekend = (time) => {
  // Custom logic to check if the given time is a weekend
  // Replace this with your own implementation
  return time.day() === 6 || time.day() === 0; // Saturday or Sunday
};
const validationSchema = Yup.object().shape({
  start_time: Yup.string().required("Start time is required"),
  end_time: Yup.string().required("End time is required"),
  salary_range: Yup.string().required("Salary range is required"),
  experience: Yup.string().required("Experience is required"),
  job_location: Yup.string().required("Job location is required"),
  designation: Yup.string().required("Designation is required"),
  vacancies: Yup.number()
    .required("Number of vacancies is required")
    .min(1, "At least one vacancy is required"),
  special_note: Yup.string(),
  start_date: Yup.date().required("Start date is required"),
  end_date: Yup.date()
    .min(Yup.ref("start_date"), "End date should be greater than start date"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
});
function PostJob() {
  const { api, userData } = useGlobalContext();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [textQ, setTextQ] = useState("");
  const [chips, setChips] = useState([]);

  const [qualifications, setQualifications] = useState({
    degree: "",
    subject: "",
    year: "",
  });

  const [employmentType, setEmploymentType] = useState([]);

  const [jobDescription, setJobDescription] = useState();

  const [categories, setCategories] = useState([]);
  const [jobCategory, setJobCategory] = useState(null);
  const [open, toggleOpen] = React.useState(false);
  const [error, setError] = useState(false);
  const filter = createFilterOptions();

  const getcategories = async () => {
    try {
      const res = await api.get("/app/jobs/categories");
      if (res?.status === 200) {
        console.log("Job Categories:", res?.data);
        setCategories(res?.data);
      }
    } catch (err) {
      console.log("Job Categories:", err);
    }
  };
  //Autocomplete Box Component methods start
  const handleClose = () => {
    setDialogValue({
      name: "",
      description: "",
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
    description: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newCategoryName = dialogValue.name;

    try {
      await api.post(`/app/jobs/category`, {
        name: newCategoryName,
        description: dialogValue?.description,
      });
      toast.success("successfully created job category");
      getcategories();
    } catch (error) {
      if (error.response.status == 403) {
        toast.error("Job Category already exist");
        return;
      }
      toast.error("something went wrong");
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    getcategories();
    console.log("Job Categories:", categories);
  }, []);

  const handleClick = () => {
    if (text.trim() !== "") {
      if (chips.includes(text)) {
        toast.error("same subject not allowed");
      } else {
        setChips((prevChips) => [...prevChips, text]);
        setText("");
      }
    }
  };

  const handleDelete = (chipToDelete) => {
    setChips((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
  };

  const formik = useFormik({
    initialValues: {
      job_title: "",
      job_description: "",
      employment_type: employmentType,
      work_schedule: "",
      start_time: "",
      end_time: "",
      salary_range: "",
      is_negotiable: true,
      subjects: [],
      minimum_qualification: qualifications,
      experience: "0-2 Years",
      job_location: "",
      designation: "Teacher",
      vacancies: 1,
      special_note: "",
      start_date: "",
      end_date: "",
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values = {
        ...values,
        work_schedule: {
          start_time: values?.start_time,
          end_time: values?.end_time,
        },
        minimum_qualification: qualifications,
        subjects: chips,
        employment_type: employmentType,
        job_description: jobDescription,
        institute_id: userData?.id,
        category_id: jobCategory?.id || null,
        role: userData?.role,
      };
      setError(false);
      if (!jobCategory?.name) {
        setError(true);
        toast.error("Job Category is required");
        return;
      }
      try {
        const res = await api.post(`/app/jobs/post`, values);
        if (res?.status === 200) {
          navigate("/institute/jobs");
          toast.success("Job Posted Successfully");
        }
      } catch (err) {
        if (err.response.status === 403) {
          toast.error("You are not a user of institute");
          return;
        }
        toast.error("Error while posting job");
      }
    },
  });
  console.log(userData);

  return (
    <>
      <div className=" container bg-white shadow-md rounded p-3 mb-2" style={{ height: '85vh', overflowY: 'auto' }}>
        <Container
          className="p-4 bg-light shadow rounded rounded-sm-0 "
          style={{
            backgroundImage: "linear-gradient(45deg, #472fcb, #00a4a5)",
          }}
        >
          <h1 className="fs-60 fw-bold mb-3 text-center text-white">
            {" "}
            Post a new job{" "}
          </h1>
          <h5 className="fs-25 font-weight-bold text-bold mb-3 text-center text-white">
            Please fill out the following fields below <br /> to create a new
            job and submit it.
          </h5>
          <div className="d-flex align-items-end justify-content-center">
            <div className="text-right me-4 top-0 m-2 ">
              <Link
                to="/institute/dashboard"
                className="btn border-2  theme-btn bg-light text-dark rounded border me-4 font-weight-bold "
              >
                Go to Dashboard
              </Link>
            </div>
          </div>
        </Container>
        <div className="d-flex flex-column align-items-center justify-content-between flex-wrap p-4">
          <div className="d-flex align-items-center">
            <h1 className=" fw-bold text-info mb-3">Choose a Category</h1>
          </div>
          <div className="d-flex align-items-center">
            <FormControl id="free-solo-dialog-demo">
              <Autocomplete
                slotProps={{
                  listbox: {
                    sx: (theme) => ({
                      zIndex: theme?.vars?.zIndex?.modal,
                    }),
                  },
                }}
                variant="soft"
                placeholder="Select Category"
                size="lg"
                id="jobCategory"
                name="jobCategory"
                fullWidth
                value={jobCategory}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                      toggleOpen(true);
                      setDialogValue({
                        name: newValue,
                        description: "",
                      });
                    });
                  } else if (newValue && newValue?.inputValue) {
                    toggleOpen(true);
                    setDialogValue({
                      name: newValue?.inputValue,
                      description: "",
                    });
                  } else {
                    setJobCategory(newValue);
                    formik.setFieldValue("jobCategory", newValue);
                  }
                }}
                onBlur={formik.handleBlur}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (params?.inputValue !== "") {
                    filtered?.push({
                      inputValue: params?.inputValue,
                      name: `Add "${params?.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                options={categories}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option?.inputValue) {
                    return option?.inputValue;
                  }
                  return option?.name; // Assuming the field name is "name" in your categories array
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => (
                  <AutocompleteOption
                    slotProps={{
                      listbox: {
                        sx: (theme) => ({
                          zIndex: theme?.vars?.zIndex?.modal,
                        }),
                      },
                    }}
                    {...props}
                  >
                    {option?.name}
                  </AutocompleteOption>
                )}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid black",
                  "& .MuiInputBase-input::placeholder": {
                    color: "black",
                  },
                }}
              />
              {error && (
                <p className="text-danger m-1">Job Category is required</p>
              )}
            </FormControl>
            <Modal open={open} onClose={handleClose}>
              <ModalDialog>
                <form onSubmit={handleSubmit}>
                  <Typography
                    id="basic-modal-dialog-title"
                    component="h2"
                    level="inherit"
                    fontSize="1.25em"
                    mb="0.25em"
                  >
                    Add a new Job category
                  </Typography>

                  <Stack spacing={2}>
                    <FormControl id="name">
                      <FormLabel>Name</FormLabel>
                      <Input
                        autoFocus
                        type="text"
                        value={dialogValue.name}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            name: event.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl id="description">
                      <FormLabel>Description</FormLabel>
                      <Input
                        type="text"
                        value={dialogValue.description}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            description: event.target.value,
                          })
                        }
                      />
                    </FormControl>

                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                    >
                      <Button
                        variant="plain"
                        color="neutral"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button type="submit">Add</Button>
                    </Stack>
                  </Stack>
                </form>
              </ModalDialog>
            </Modal>
          </div>
        </div>
        <div className="container p-3">
          <div className="row row-cols-1 row-cols-lg-2 ">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Job Title</h5>
              <p className="font-weight-bold">
                A job title must describe one position only
              </p>
            </div>
            <div className="col">
              <Input
                placeholder="e.g. 'Software Developer'"
                name="job_title"
                value={formik?.values?.job_title}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2 ">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Job description</h5>
              <p className="font-weight-bold">
                Provide a short description about the job. Keep <br></br>it
                short and to the point.
              </p>
            </div>
            <div className="col">
              {/* <Textarea minRows={8} placeholder="Description" /> */}
              <ReactQuill
                className="rounded shadow"
                placeholder="Write description"
                name="job_description"
                value={jobDescription}
                onChange={setJobDescription}
              />
            </div>
          </div>
        </div>
        <hr></hr>

        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold p-1">Employment Type</h5>
            </div>
            <div className="col">
              <div
                className="shadow rounded"
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  className="mt-1 "
                  label="Part-Time"
                  name="employment_type"
                  value={formik?.values?.employment_type}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEmploymentType([...employmentType, "Part Time"]);
                    } else {
                      setEmploymentType(
                        employmentType.filter((e) => e !== "Part Time")
                      );
                    }
                  }}
                />
              </div>
              <div
                className="shadow rounded"
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  className="mt-1 "
                  label="Full-Time"
                  name="employment_type"
                  value={formik?.values?.employment_type}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEmploymentType([...employmentType, "Full-Time"]);
                    } else {
                      setEmploymentType(
                        employmentType.filter((e) => e !== "Full-Time")
                      );
                    }
                  }}
                />
              </div>
              <div
                className="shadow rounded"
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  className="mt-1 "
                  label="internship"
                  name="employment_type"
                  value={formik?.values?.employment_type}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEmploymentType([...employmentType, "internship"]);
                    } else {
                      setEmploymentType(
                        employmentType.filter((e) => e !== "internship")
                      );
                    }
                  }}
                />
              </div>
              <div
                className="shadow rounded"
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  className="mt-1 "
                  label="Guest Lecturer"
                  name="employment_type"
                  value={formik?.values?.employment_type}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEmploymentType([...employmentType, "Guest Lecturer"]);
                    } else {
                      setEmploymentType(
                        employmentType.filter((e) => e !== "Guest Lecturer")
                      );
                    }
                  }}
                />
              </div>
              <div
                className="shadow rounded"
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  className="mt-1 "
                  label="Online"
                  name="employment_type"
                  value={formik?.values?.employment_type}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEmploymentType([...employmentType, "Online"]);
                    } else {
                      setEmploymentType(
                        employmentType.filter((e) => e !== "Online")
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Working Schedule</h5>
              <p className="font-weight-bold">
                You can pick Time For work schedule
              </p>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <FormControl>
                    <FormLabel>Start Time</FormLabel>
                    <Input
                      type="time"
                      name="start_time"
                      value={formik?.values?.start_time}
                      onChange={formik?.handleChange}
                    />
                    {formik?.touched?.start_time &&
                      formik?.errors?.start_time && (
                        <FormHelperText error className="text-danger">
                          start_time is required
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl>
                    <FormLabel>End Time</FormLabel>
                    <Input
                      type="time"
                      name="end_time"
                      value={formik?.values.end_time}
                      onChange={formik?.handleChange}
                    />
                    {formik?.touched?.end_time && formik?.errors?.end_time && (
                      <FormHelperText error className="text-danger">
                        end_time is required
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Salary</h5>
              <p className="font-weight-bold">
                Choose How You Prefer to pay for this job
              </p>
            </div>
            <div className="col">
              <div className="row ">
                <div className="col mt-10px  mb-4">
                  <Input
                    placeholder="Amount Youn Want To Pay…"
                    name="salary_range"
                    value={formik?.values.salary_range}
                    onChange={formik?.handleChange}
                  />
                  <p>Salary  /month</p>
                  {formik?.touched?.salary_range &&
                    formik?.errors?.salary_range && (
                      <FormHelperText error className="text-danger">
                        salary is required
                      </FormHelperText>
                    )}
                </div>
              </div>
              <Checkbox
                label="Salary is Negotiable"
                variant="soft"
                defaultChecked
                name="is_negotiable"
                value={formik?.values?.is_negotiable}
                onChange={formik?.handleChange}
              />
            </div>
          </div>
        </div>
        <hr></hr>

        <div className="container p-3">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Add Subjecs / Experties </h5>
              <p className="font-weight-bold ">
                Add all the subjects that your candidate should have expertised
                in
              </p>
            </div>
            <div className="col">
              <div>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu"></IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Add Subject / Experties "
                    // inputProps={{ "aria-label": "search google maps" }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                    onClick={handleClick}
                  >


                    <AddIcon />
                  </IconButton>
                </Paper>
                <Stack direction="row" spacing={1} className="mt-3 w-50 ">
                  {chips.map((chip) => (
                    <Chip
                      key={chip}
                      label={chip}
                      variant="outlined"
                      onClick={() => console.log(`Clicked: ${chip}`)} // Add your custom click event handler
                      onDelete={() => handleDelete(chip)}
                    // sx={{ maxWidth: "100px" }} // Adjust the maximum width as per your requirements
                    />
                  ))}
                </Stack>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container p-3">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold ">Minimum Qualification and Year of Completion : </h5>
              <p className="font-weight-bold">
                This will be displayed on job page for candidates to see
              </p>
            </div>
            <div className="col ">
              <div className="row row-cols-3">
                <div className="col">
                  <Input
                    placeholder="Degree"
                    value={qualifications?.degree}
                    onChange={(e) =>
                      setQualifications({
                        ...qualifications,
                        degree: e.target.value,
                      })
                    }
                  />
                  {formik?.touched.minimum_qualification?.degree &&
                    formik?.errors.minimum_qualification?.degree && (
                      <FormHelperText error className="text-danger">
                        degree is required
                      </FormHelperText>
                    )}
                </div>
                <div className="col">
                  <Input
                    placeholder="Subject"
                    value={qualifications?.subject}
                    onChange={(e) =>
                      setQualifications({
                        ...qualifications,
                        subject: e.target.value,
                      })
                    }
                  />
                  {formik?.touched.minimum_qualification?.subject &&
                    formik?.errors.minimum_qualification?.subject && (
                      <FormHelperText error className="text-danger">
                        subject is required
                      </FormHelperText>
                    )}
                </div>
                <div className="col">
                  <Input
                    placeholder="Year"
                    type="month"
                    value={qualifications?.year}
                    onChange={(e) =>
                      setQualifications({
                        ...qualifications,
                        year: e.target.value,
                      })
                    }
                  />
                  {formik?.touched?.minimum_qualification?.year &&
                    formik?.errors?.minimum_qualification?.year && (
                      <FormHelperText error className="text-danger">
                        year is required
                      </FormHelperText>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-4 ">
              <h5 className="font-weight-bold mb-3">Job Location</h5>
              {/* <p className="font-weight-bold">
                Provide a short description about the job. Keep <br></br>it
                short and to the point.
              </p> */}
            </div>
            <div className="col mb-3">
              <Input
                placeholder="Add Job Location"
                name="job_location"
                value={formik.values.job_location}
                onChange={formik.handleChange}
              />
              {formik.touched.job_location && formik.errors.job_location && (
                <FormHelperText error className="text-danger">
                  location is required
                </FormHelperText>
              )}
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-4 ">
              <h5 className="font-weight-bold mb-3">Experience</h5>
              {/* <p className="font-weight-bold">
                Provide a short description about the job. Keep <br></br>it
                short and to the point.
              </p> */}
            </div>
            <div className="col mb-3">
              <Select
                placeholder="Select Years experience "
                indicator={<KeyboardArrowDown />}
                sx={{
                  width: 240,
                  [`& .${selectClasses.indicator}`]: {
                    transition: "0.2s",
                    [`&.${selectClasses.expanded}`]: {
                      transform: "rotate(-180deg)",
                    },
                  },
                }}
                name="experience"
                defaultValue={formik.values.experience}
                onChange={formik.handleChange}
              >
                <Option value="0-2 Years">0-2 years</Option>
                <Option value="2-5 Years">2-5 years</Option>
                <Option value="5+ Years">5+ years</Option>
              </Select>
              {formik.touched.experience && formik.errors.experience && (
                <FormHelperText error className="text-danger">
                  experience is required
                </FormHelperText>
              )}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold mb-3">Designation</h5>
              {/* <p className="font-weight-bold">
                Provide a short description about the job. Keep <br></br>it
                short and to the point.
              </p> */}
            </div>

            <div className="col mb-3">
              <Select
                placeholder="Select a Designation"
                indicator={<KeyboardArrowDown />}
                sx={{
                  width: 240,
                  [`& .${selectClasses.indicator}`]: {
                    transition: "0.2s",
                    [`&.${selectClasses.expanded}`]: {
                      transform: "rotate(-180deg)",
                    },
                  },
                }}
                name="designation"
                defaultValue={formik.values.designation}
                onChange={formik.handleChange}
              >
                <Option value="Professionals">Professionals</Option>
                <Option value="Teacher">Teacher</Option>
                <Option value="Principal">Principal</Option>
                <Option value="Librarian">Librarian</Option>
                <Option value="School Counselor">School Counselor</Option>
                <Option value="Other">Other</Option>
              </Select>
              {formik.touched.designation && formik.errors.designation && (
                <FormHelperText error className="text-danger">
                  designation is required
                </FormHelperText>
              )}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-4 ">
              <h5 className="font-weight-bold mb-3">No of Vacancies</h5>
            </div>
            <div className="col">
              <Stack spacing={1.5}>
                <Input
                  type="number"
                  placeholder="Add Vacancy"
                  defaultValue={1}
                  name="vacancies"
                  value={formik.values.vacancies}
                  onChange={formik.handleChange}
                />
                {formik.touched.vacancies && formik.errors.vacancies && (
                  <FormHelperText error className="text-danger">
                    vacancy is required
                  </FormHelperText>
                )}
              </Stack>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-4 ">
              <h5 className="font-weight-bold ">Contact Details</h5>
              <p className="font-weight-bold">
                Provide a contact details of the contact person for the position
              </p>
            </div>
            <div className="col">
              <Stack direction="column" flexWrap="wrap" spacing={1.5}>
                <FormControl className="col">
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl className="col">
                  <FormLabel>Phone Number*</FormLabel>
                  <Input
                    type="text"
                    required
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <FormHelperText error className="text-danger">
                      {formik.errors.phone}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl className="col">
                  <FormLabel>Email*</FormLabel>
                  <Input
                    type="email"
                    required
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormHelperText error className="text-danger">
                      {formik.errors.email}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-4 ">
              <h5 className="font-weight-bold ">Job Start & End Date</h5>
              <p className="font-weight-bold">
                Provide a Start And End Date Of Job Application
              </p>
            </div>
            <div className="col">
              <div className="row d-flex flex-wrap mb-4">
                <FormControl className="col">
                  <FormLabel>Start date*</FormLabel>
                  <Input
                    type="date"
                    required
                    name="start_date"
                    value={formik.values.start_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.start_date && formik.errors.start_date && (
                    <FormHelperText error className="text-danger">
                      {formik.errors.start_date}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl className="col">
                  <FormLabel>End date*</FormLabel>
                  <Input
                    type="date"
                    required
                    name="end_date"
                    value={formik.values.end_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.end_date && formik.errors.end_date && (
                    <FormHelperText error className="text-danger">
                      {formik.errors.end_date}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <Divider />
        <div className="container p-3 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Special Note</h5>
              <p className="font-weight-bold">
                Provide a important note if applicable
              </p>
            </div>
            <div className="col">
              <Textarea
                minRows={4}
                placeholder="Note"
                name="special_note"
                value={formik.values.special_note}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <hr></hr>
        <div></div>
        <div className="text-center mt-5 mb-4">
          <Button variant="soft" onClick={formik.handleSubmit}>
            Post a new job
          </Button>
        </div>
      </div>
    </>
  );
}

export default React.memo(PostJob);
