import { Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Sidebar from './SideBar/Sidebar';
import Mainchat from './mainChat/Mainchat';
import { useGlobalContext } from "global/context";
import io from 'socket.io-client';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const PATH = process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"

const StyledMessagingContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 85vh; /* 100vh minus combined height of header and footer */
  max-width: 1200px; /* Limit width to avoid full stretch */
  margin: 0 auto; /* Center the container */
  overflow-y: hidden;
  padding: 0 1rem; /* Add horizontal padding for a neat look */

  @media (max-width: 768px) {
    flex-direction: column;
    height: 94vh; /* Adjust this based on actual header and footer height */
    padding: 0; /* Remove padding for smaller screens */
  }

  @media (max-width: 600px) {
    height: 94vh; /* Modify if header/footer heights differ */
  }
`;



const Messaging = () => {
  const { userData, api, notification } = useGlobalContext();
  const socketRef = useRef();
  const location = useLocation();
  const [profileData, setProfileData] = useState([]);
  const [isConnect, setIsConnect] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [allMsg, setAllMsg] = useState([]);
  const [roomData, setRoomData] = useState("");
  const [sender, setSender] = useState([]);
  const [msg, setMsg] = useState("");
  const [senderUser, setSenderUser] = useState({})
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);  // Detect mobile view
  const [showChat, setShowChat] = useState(!isMobile);  // Default to showing chat if on mobile

  console.log("Sender user : ", senderUser)
  useEffect(() => {
    // Handle window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // console.log("role : ",userData.role,
  //   allMsg.length > 0 ? allMsg[0].sender.role : null,)

  const handleSendMsg = async (msg) => {
    console.log("message reply : ", msg)
    console.log("Room : ", roomData)
    console.log("senderRole role : ", userData)
    console.log("socketRef.current  : ", socketRef.current)
    if (socketRef.current.connected) {
      const data = {
        sender_role: userData?.role,
        receiver_role: senderUser.role,
        msg,
        receiver: senderUser.id,
        sender: userData.id,
        room: senderUser.id + userData.id,
        time_to_send: new Date(),
      };
      console.log("Data hai ye  : ", data)
      socketRef.current.emit("SEND_MSG", data);
      setAllMsg(prevState => [...prevState, data]);
    }
  };

  const getAllMessages = async () => {
    try {
      const res = await api.get(`app/message/allmsg/${notification?.content_id}`, {
        params: {
          receiver_role: userData.role, // Assuming sender_role comes from userData
          sender_role: allMsg.length > 0 ? allMsg[0].sender.role : null, // Assuming receiver_role from allMsg
        }
      });

      setAllMsg(res.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchProfileData = async (id) => {
    try {
      let res;
      if (userData.role !== 'institute') {
        res = await api.get(`/app/user/${userData?.id}`);
        if (res.status === 200) {
          setProfileData(res.data);
        }
      } else {
        res = await api.get(`/app/institutes/${id}`);
        if (res.status === 200) {
          setProfileData(res.data.institute);
        }
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  const fetchSenderProfiles = async (uniqueSenders) => {
    try {
      const senderProfiles = await Promise.all(
        uniqueSenders.map(async (notification) => {
          const res = await api.get(`/app/user/${notification.sender_user_id}`);
          return res?.status === 200 ? res.data : null;
        })
      );

      // console.log("SenderProfilre : ",senderProfiles)
      setSender(senderProfiles.filter(profile => profile !== null));
    } catch (error) {
      console.log('Error fetching sender profiles:', error);
    }
  };

  const handleReply = (e) => {
    e.preventDefault();
    if (msg) {
      //  console.log(msg)
      handleSendMsg(msg);
      setMsg("");
    }
  };

  useEffect(() => {
    if (notification && notification.length > 0) {
      const uniqueSenders = notification.reduce((acc, notification) => {
        if (!acc.some(item => item.sender_user_id === notification.sender_user_id)) {
          acc.push(notification);
        }
        return acc;
      }, []);
      // console.log("UniQue Sender : ",uniqueSenders)
      fetchSenderProfiles(uniqueSenders);
    }
  }, [notification]);

  useEffect(() => {
    fetchProfileData(userData?.id);
    getAllMessages();
    const socket = io.connect(PATH);
    socketRef.current = socket;

    socket.on('connect', () => {
      setIsConnect(true);
      console.log("Socket connected:", socket.connected); // Add this line
    });

    socket.on('disconnect', () => {
      setIsConnect(false);
      console.log("Socket disconnected:", socket.connected); // Add this line
    });

    return () => {
      socket.disconnect();
    };
  }, [userData?.id]);

  useEffect(() => {
    if (isConnect) {
      console.log("ye Soc : ", socketRef.current)
      socketRef.current.emit('ADD_USER', userData);
      socketRef.current.on('USER_ADDED', (data) => {
        console.log(data)
        setOnlineUsers(data);

      });
      socketRef.current.on("RECIVED_MSG", (data) => {
        const droom = data?.room - userData.id
        console.log("user : ", isNaN(Number(senderUser.id)) ? droom : Number(senderUser.id));
        console.log("droom : ", droom)
        console.log("user : ", Number(senderUser.id))
        console.log("dusra: ", Number(userData.id))
        const room = (isNaN(Number(senderUser.id)) ? droom : Number(senderUser.id)) + Number(userData.id);
        console.log(data)
        console.log(roomData)
        console.log(typeof data?.room, typeof room);
        console.log("data.room:", data.room, room);

        if (data?.room === room) {
          console.log('if condition is running')
          setAllMsg(prevState => [...prevState, data]);
        } else {
          console.log('Message received for a different room', data);
        }
      });

      return () => socketRef.current.disconnect();
    }
  }, [isConnect, roomData.roomId]);

  const handleUserClick = (user) => {
    setShowChat(true);
  };

  const handleBackToSidebar = () => {
    setShowChat(false);
  };

  if (!userData) return null;

  return (
    <StyledMessagingContainer className={!isMobile ? 'detailed-view p-1 m-3 shadow-sm rounded-3 border border-success rounded' : ' '}>
      <Paper square elevation={0} sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>       {/* Conditional rendering based on screen size and chat view state */}
        {isMobile ? (
          showChat ? (
            <Mainchat
              sender={sender}
              pd={profileData}
              roomData={roomData}
              handleSendMsg={handleSendMsg}
              allMsg={allMsg}
              handleReply={handleReply}
              msg={msg}
              setMsg={setMsg}
              onBack={handleBackToSidebar}
            />
          ) : (
            <Sidebar
              sender={sender}
              notification={notification}
              pd={profileData}
              onlineUsers={onlineUsers}
              roomData={roomData}
              setRoomData={setRoomData}
              setAllMsg={setAllMsg}
              onUserClick={handleUserClick}
              setSenderUser={setSenderUser}
            />
          )
        ) : (
          <>
            <Sidebar
              sender={sender}
              notification={notification}
              pd={profileData}
              onlineUsers={onlineUsers}
              roomData={roomData}
              setRoomData={setRoomData}
              setAllMsg={setAllMsg}
              onUserClick={handleUserClick}
              setSenderUser={setSenderUser}
            />
            <Mainchat
              sender={sender}
              pd={profileData}
              roomData={roomData}
              handleSendMsg={handleSendMsg}
              allMsg={allMsg}
              handleReply={handleReply}
              msg={msg}
              setMsg={setMsg}
            />
          </>
        )}
      </Paper>
    </StyledMessagingContainer>
  );
};

export default Messaging;