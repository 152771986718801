import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Avatar, Box, List, Tab, Tabs } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ChatIcon from "@mui/icons-material/Chat";
import styled from "styled-components";
import { useGlobalContext } from "global/context";

const SidebarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 350px;


  @media (max-width: 768px) {
    width: 100%;
    height: 94vh; 
    margin: 0; 
    padding: 0;
    
    /* Styles for notification-list in mobile view */
    .notification-list {
      width: 100%; /* Full width on mobile */
    }

    .notification-list > div {
      width: 100%; /* Ensure child div also takes full width */
  }
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 94vh; 
  }

  .chat-box {
    flex-grow: 1;
    overflow-y: auto;

    @media (max-width: 768px) {
      width: 100%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for webkit browsers */
    } 

  .user-list {
    flex-grow: 1;
    overflow-y: auto;
    height: 600px; /* Default height for user list */

    @media (max-width: 768px) {
      width: 100vw;
      height: calc(60vh - 2px);
    }

    @media (max-width: 600px) {
      width: 100vw;
      height: calc(60vh - 2px);
    }
  }
  }
`;
const Sidebar = ({ sender = [], notification, pd, onlineUsers, roomData, setRoomData, setAllMsg, onUserClick, setSenderUser }) => {
  const { userData, api } = useGlobalContext();
  const [value, setValue] = useState(0);
  const [connections, setConnections] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set mobile view for screen width 768px or less
    };

    // Set the initial state
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleUserClick = (user) => { // Log to check if user is clicked
    onUserClick(user);  // Call the parent function to show chat
  };

  const handleRooms = (user) => {
    const roomId = user.id + userData.id;
    console.log("Fetching messages for room: ", roomId);

    api.get(`app/message/allmsg/${roomId}`)
      .then((res) => {
        setRoomData(roomId);  // Set the clicked user as the roomData
        setAllMsg(res.data);
        console.log("res.data : ",res.data)
        console.log("Sender : ",user)

        setSenderUser(user);
      })
      .catch((error) => {
        console.error("Error fetching messages for room:", error);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getConnections = async () => {
    try {
      const res = await api.get(`/app/connections?userId=${userData?.id}`);
      if (res?.status === 200) {
        setConnections(res?.data?.connections);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (userData?.id) {
      getConnections();
    }
  }, [userData]);

  // console.log("Online User : ",onlineUsers)

  const isUserOnline = (user) => {
    return onlineUsers.some((onlineUser) => {
      // console.log("User : ", onlineUser.userData);
      return (
        onlineUser.userData.id === user.id ||
        (user.institute && onlineUser.userData.id === user.institute.id)
      );
    });
  };

  // console.log("Ye hai : ",isUserOnline)
  return (
    <SidebarContainer>
      <div
        className={!isMobile ? 'detailed-view p-3 m-1 shadow-sm rounded-3 border border-success rounded' : ' m-1 rounded-3 border border-success rounded'}
        style={{ height: isMobile ? '94vh' : '84vh' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Header pd={pd} />
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Chat/User tabs"
            variant="fullWidth"
          >
            <Tab icon={<ChatIcon fontSize="small" />} iconPosition="start" label="Chat List" sx={{ minHeight: "inherit", color: '#22c55e' }} />
            {/* <Tab icon={<PersonIcon fontSize="small" />} iconPosition="start" label="User List" sx={{ minHeight: "inherit", color: '#22c55e' }} /> */}
          </Tabs>

          {value === 0 && (
            <div className='chat-box'>
              {sender.length === 0 ? (
                <p>Loading Chats Please Wait ....</p>
              ) : (
                <List>
                  {sender.map((user) => {
                    const isOnline = isUserOnline(user);
                    console.log(isOnline)
                    return (
                      <div key={user.id} className='container d-flex flex-wrap' onClick={() => handleUserClick(user)}>
                        <div className='notification-list'>
                          <div
                            className='d-flex justify-content-between align-items-center p-3 hover-s shadow-sm rounded-3 mb-3'
                            onClick={() => handleRooms(user)}
                            style={{
                              cursor: 'pointer',
                              width: '100%',
                              height: '80px',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              border: isOnline ? '2px solid green' : 'none',
                            }}
                          >
                            <div className='d-flex'>
                              <Avatar
                                alt={user.name}
                                src={user.avatar_url || 'https://icdn.isrgrajan.com/in/2021/05/Gurukul-ancient-indian-school.jpg'}
                                className="mr-3"
                                sx={{ width: "60px", height: "60px" }}
                              />
                              <div style={{ lineHeight: "20px" }}>
                                <p className='fs-14'>{user.first_name + ' ' + user.last_name}</p>
                                <p className='fs-11'>{user.email}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </List>
              )}
            </div>
          )}

          {/* User List */}
          {/* {value === 1 && (
            <div className='user-list'>
              <div>Show user connections here</div>
            </div>
          )} */}
        </Box>
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;
