import React, { useEffect, useState } from "react";
import { useGlobalContext } from "global/context";
import EventPage from './EventPage'
import { useParams } from "react-router-dom";

const SinglePageEvent = () => {
    // const { id } = useParams();
    const { userData, api } = useGlobalContext();
    const [data, setData] = useState([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    console.log("Current Event ID from URL:", id); // Log the id here

    const getAllEvents = async () => {
        console.log("Fetching event data for ID:", id); // Log before the API call
        try {
            const res = await api.get(`/app/events/getEventByeventId/${id}`);
            console.log("API Response:", res); // Log the response
            if (res?.status === 200) {
                setData(res?.data?.results || []);
            } else {
                throw new Error(`Unexpected response status: ${res?.status}`);
            }
        } catch (e) {
            console.error("Error fetching events:", e);
            setError("Failed to load event data.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
            getAllEvents();
    }, []);

    console.log("data : ",data)

    return (
        <>
            <div
                style={{
                    maxHeight: '750px',
                    overflowY: 'scroll',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                }}
                className="scroll-container m-6"
            >
                {loading ? (
                    <div style={{ textAlign: "center", padding: "20px" }}>
                        <p>Loading...</p>
                    </div>
                ) : error ? (
                    <div style={{ textAlign: "center", color: "red" }}>
                        <p>{error}</p>
                    </div>
                ) : (
                    <div>
                        <EventPage data={data} />
                    </div>
                )}
            </div>
            <style>
                {`
                .scroll-container::-webkit-scrollbar {
                    display: none;
                }
                `}
            </style>
        </>
    );
};

export default SinglePageEvent;
