import { Avatar, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Input, Table } from "@mui/joy";
import SearchIcon from "@mui/icons-material/Search";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import { useNavigate} from "react-router-dom";
import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";

import IconButton from "@mui/material/IconButton";
import { useGlobalContext } from "global/context";

import { styled } from "styled-components";
import { height } from "@mui/system";

function InstituteProfile() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, api } = useGlobalContext();
  const [profileData, setProfileData] = useState();
  const [hasShadow, setHasShadow] = useState(true);
  console.log("Institute id : ",location?.state)
  const getProfileData = async () => {
    const fetchProfileData = async (id) => {
      try {
        const res = await api.get(`/app/institutes/${id}`);
        if (res?.status === 200) {
          console.log(
            "Institute Profile Data:------------------------- ",
            res?.data
          );
          setProfileData(res?.data?.institute);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const profileId = id || userData?.id;
    // const profileId = userData?.id;

    console.log("profile : ",profileId)
    console.log("profile : ",userData)

    if (profileId) {
      await fetchProfileData(profileId);
    }
  };

  useEffect(() => {
    getProfileData();
    const handleResize = () => {
      setHasShadow(window.innerWidth > 767);
    };
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleEvent = () =>{
    navigate("/institute/event")
  }

  const customStyle = {
    borderRadius: "0.375rem",
    boxShadow: hasShadow ? "0 0.25rem 0.5rem rgba(0, 0, 0, 0.15)" : "none",
  };

  const ScrollerStyle = {
    height: "100vh",
    overflowY: "scroll",
    justifyContent: "flex-end",
    // marginTop: "140px",
  };

  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col col-12 col-lg-12" >
            <div className="row ">
              <div className="col">
                <div className="row">
                  <div className="col " style={ScrollerStyle}>
                    <div className="">
                      <div className="p-3">
                        <div className=" row  rounded-3  " style={customStyle}>
                          <div className="col-lg-5 col-12">
                            <div className="p-1 mt-5">
                              <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5oJVJTFpZozCqbbLzzKW-MKnhwl9SINNCjA&s"
                                alt="logo"
                                style={{
                                  width: "100%",
                                  borderRadius: "30px",
                                  height: "100%",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-7 col-12">
                            <div className="p-3">
                              <div className="d-flex align-items-center ">
                                <div>
                                  <h1 className="font-weight-bold mb-1">
                                    {profileData?.name || userData?.name}
                                  </h1>
                                </div>{" "}
                                &nbsp;&nbsp;&nbsp;
                                <div>
                                  <Button
                                    variant="outlined"
                                    size="sm"
                                    color="success"
                                    style={{ borderRadius: "5px" }}
                                  >
                                    Institute
                                  </Button>
                                </div>
                              </div>
                              <div className="d-flex fs-18 mb-1 ">
                                <p className="mr-2 text-dark">
                                  <strong className="text-dark">Address</strong>
                                  &nbsp;&nbsp;
                                  {
                                    profileData?.institute_profiles[0]?.address
                                  }, {profileData?.institute_profiles[0]?.city},{" "}
                                  {profileData?.institute_profiles[0]?.state},{" "}
                                  {profileData?.institute_profiles[0]?.country},{" "}
                                  {
                                    profileData?.institute_profiles[0]
                                      ?.postal_code
                                  }
                                </p>
                              </div>
                              <div className="d-flex fs-18 mb-1">
                                <p className="mr-3 text-dark">
                                  <strong className="text-dark">Website</strong>
                                  &nbsp;&nbsp;
                                  <a
                                    href={
                                      profileData?.institute_profiles[0]
                                        ?.website
                                    }
                                  >
                                    {
                                      profileData?.institute_profiles[0]
                                        ?.website
                                    }
                                  </a>
                                </p>
                              </div>
                              <div>
                                <p className=" text-dark fs-18 mb-1">
                                  <strong className="text-dark">Email</strong>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {profileData?.email || userData?.email}
                                </p>
                              </div>
                              <div>
                                <p className="mb-1 text-dark fs-18 mb-1 ">
                                  <strong className="text-dark">Phone</strong>
                                  &nbsp;&nbsp;&nbsp; &nbsp;
                                  {profileData?.mobile}
                                </p>
                              </div>
                              <Box>
                                <a
                                  href={
                                    profileData?.institute_profiles[0]?.twitter
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IconButton>
                                    <i
                                      className="bi bi-twitter "
                                      style={{ color: "#17a2b8" }}
                                    ></i>
                                  </IconButton>
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                  href={
                                    profileData?.institute_profiles[0]
                                      ?.instagram
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IconButton>
                                    <i
                                      className="bi bi-instagram  "
                                      style={{ color: "red" }}
                                    ></i>
                                  </IconButton>
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                  href={
                                    profileData?.institute_profiles[0]?.github
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IconButton>
                                    <i className="bi bi-github  "></i>
                                  </IconButton>
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                  href={
                                    profileData?.institute_profiles[0]?.facebook
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IconButton>
                                    <i
                                      className="bi bi-facebook  "
                                      style={{ color: "blue" }}
                                    ></i>
                                  </IconButton>
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                  href={
                                    profileData?.institute_profiles[0]?.linkdin
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IconButton>
                                    <i
                                      className="bi bi-linkedin fs-21  "
                                      style={{ color: "#007bff" }}
                                    ></i>
                                  </IconButton>
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                  href={
                                    profileData?.institute_profiles[0]?.youtube
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IconButton>
                                    <i
                                      className="bi bi-youtube  "
                                      style={{ color: "red" }}
                                    ></i>
                                  </IconButton>
                                </a>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className=" rounded-3   mb-3 p-4"
                        style={customStyle}
                      >
                        <h4 className="font-weight-bold mb-2">About Us</h4>
                        <p>{profileData?.institute_profiles[0]?.aboutYou}</p>
                      </div>
                      <div
                        className=" rounded-3   mb-3 p-3"
                        style={customStyle}
                      >
                        <div className="p-0">
                          <Table aria-label="basic table" className="">
                            <thead>
                              <tr>
                                <th
                                  className="font-weight-bold text-black"
                                  style={{ width: "20%" }}
                                >
                                  <h4 className="font-weight-bold ">
                                    Additional Information
                                  </h4>
                                </th>
                                <th
                                  className="font-weight-bold text-black"
                                  style={{ width: "35%", userSelect: "none" }}
                                ></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ width: "20%" }}>
                                  <h6 className="font-weight-bold">
                                    Institute Establishment Date -{" "}
                                  </h6>
                                </td>
                                <td>
                                  <p>
                                    {
                                      profileData?.institute_profiles[0]
                                        ?.establishmentDate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "20%" }}>
                                  <h6 className="font-weight-bold">
                                    Affiliated by -{" "}
                                  </h6>
                                </td>
                                <td>
                                  <p>
                                    {
                                      profileData?.institute_profiles[0]
                                        ?.university?.name
                                    }
                                  </p>
                                </td>
                              </tr>
                              {/* <tr>
                                <td>
                                  <h6 className="font-weight-bold">
                                    Hostel -{" "}
                                  </h6>
                                </td>
                                <td>
                                  <p>{profileData?.dayboardingHostel}</p>
                                </td>
                              </tr> */}

                              <tr>
                                <td>
                                  <h6 className="font-weight-bold">
                                    Institute Registration number -{" "}
                                  </h6>
                                </td>
                                <td>
                                  <p>
                                    {
                                      profileData?.institute_profiles[0]
                                        ?.instituteRegistrationNumber
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="rounded-3  mb-3 " style={customStyle}>
                        <div className="p-4">
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <div>
                              <h4 className="font-weight-bold">Departments</h4>
                            </div>
                            <div>
                              <Button
                                variant="outlined"
                                size="sm"
                                className="fs-11 font-weight-bold"
                                onClick={() => {
                                  if (userData?.id === profileData.id) {
                                    navigate("/institute/profile/departments");
                                  } else {
                                    navigate("/institute/departments");
                                  }
                                }}
                              >
                                View All
                              </Button>
                            </div>
                          </div>
                          <div>
                            {profileData?.institute_departments &&
                              profileData?.institute_departments?.map(
                                (department, index) => (
                                  <div className="">
                                    <h6 className="text-capitalize font-weight-bold">
                                      {department?.name} department
                                    </h6>
                                    {/* <p>
                                      <strong className="text-primary">
                                        {department?.institute_teachers?.length}
                                      </strong>
                                      Teachers{" "}
                                    </p> */}
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>

                      {/* <div className=" mb-1  rounded-3  " style={customStyle}>
                        <div className=" col-12 p-2 ">
                          <div className="p-2">
                            <div className="d-flex justify-content-between mb-3">
                              <h4 className="font-weight-bold p-2">Awards</h4>
                            </div>
                            <div
                              className="d-flex justify-content-between mb-2 "
                              style={{
                                overflow: "auto",
                              }}
                            >
                              <div className="p-1 d-flex align-items-center flex-column">
                                <Avatar
                                  className=" mb-2 "
                                  alt="Remy Sharp"
                                  src="https://img.freepik.com/premium-vector/gold-trophy-vector-illustration_945339-355.jpg?w=740"
                                  sx={{
                                    width: 76,
                                    height: 76,
                                    borderRadius: "5px",
                                  }}
                                />
                                <div
                                  className="fs-11 text-center"
                                  style={{ lineHeight: "15px" }}
                                >
                                  <p className="font-weight-bold">
                                    Excellence in Innovation Award
                                  </p>
                                  <p className="font-weight-bold">
                                    4 May , 2021
                                  </p>
                                </div>
                              </div>
                              <div className="p-1 d-flex align-items-center flex-column">
                                <Avatar
                                  className="mb-2"
                                  alt="Remy Sharp"
                                  src="https://img.freepik.com/premium-vector/award-ceremony-background-with-3d-gold-star-element-glitter-light-effect-decoration_196599-996.jpg?w=900"
                                  sx={{
                                    width: 76,
                                    height: 76,
                                    borderRadius: "5px",
                                  }}
                                />
                                <div
                                  className="fs-11 text-center"
                                  style={{ lineHeight: "15px" }}
                                >
                                  <p className="font-weight-bold">
                                    Humanitarian Achievement Medal
                                  </p>
                                  <p className="font-weight-bold">
                                    19 January , 2022
                                  </p>
                                </div>
                              </div>
                              <div className="p-1 d-flex align-items-center flex-column">
                                <Avatar
                                  className="mb-2"
                                  alt="Remy Sharp"
                                  src="https://img.freepik.com/free-vector/premium-collection-badge-design_53876-63011.jpg?w=740&t=st=1691499357~exp=1691499957~hmac=0a113c021b7db6fde044d8731dbbb0c64ceb1399f5da8093f6da5d049e041070"
                                  sx={{
                                    width: 76,
                                    height: 76,
                                    borderRadius: "5px",
                                  }}
                                />
                                <div
                                  className="fs-11 text-center"
                                  style={{ lineHeight: "15px" }}
                                >
                                  <p className="font-weight-bold">
                                    Stellar Performance Recognition
                                  </p>
                                  <p className="font-weight-bold">
                                    2 July , 2022
                                  </p>
                                </div>
                              </div>

                              <div className="p-1 d-flex align-items-center flex-column ">
                                <Avatar
                                  className="mb-2"
                                  alt="Remy Sharp"
                                  src="https://img.freepik.com/free-vector/realistic-prize-cup-design-illustration_52683-83460.jpg?w=826&t=st=1691499338~exp=1691499938~hmac=546c851b73f1781a771bbe8c7afeefa549d70371a2bf269c4732aeab7727201a"
                                  sx={{
                                    width: 76,
                                    height: 76,
                                    borderRadius: "5px",
                                  }}
                                />
                                <div
                                  className="fs-11 text-center"
                                  style={{ lineHeight: "15px" }}
                                >
                                  <p className="font-weight-bold">
                                    Lifetime Achievement Honors
                                  </p>
                                  <p className="font-weight-bold">
                                    7 January , 2020
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 ">
                    <div className=" rounded-3 mt-3 " style={customStyle}>
                      <div className=" d-none d-lg-block ">
                        <div className="form d-flex p-2 ">
                          <Input
                            fullWidth
                            placeholder="Type in here…"
                            variant="outlined"
                            sx={{ height: "60px", width: "100%" }}
                            endDecorator={<SearchIcon />}
                          />
                        </div>
                      </div>
                      <div className=" mb-2">
                        <div className="p-2">
                          <h5 className="fs-16 mb-3 font-weight-bold">
                            Personal Recommendation
                          </h5>
                          <div className="card bg-light   rounded-3  mb-3">
                            <div className="p-2 mb-1">
                              <h6 className="font-weight-bold fs-14 mb-2">
                                Ask Research Question?
                              </h6>
                              <div className="text-center mb-3">
                                <i className="bi bi-patch-question-fill fs-25 text-info"></i>
                                <h6 className="fs-13">
                                  Ask questions in Q&A to get help from experts
                                  in your field.
                                </h6>
                              </div>
                              <div className="text-center">
                                <Link to="/questions">
                                  <Button
                                    variant="outlined"
                                    fullWidth
                                    className="text-info"
                                    sx={{ color: "#17a2b8" }}
                                    size="sm"
                                  >
                                    Ask a Question
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="card   rounded-3 ">
                            <div className="p-2">
                              <h6 className="font-weight-bold fs-14 mb-3">
                                Explore Events
                              </h6>
                              <div className="text-center bg-info mb-3 shadow-sm rounded-3 p-2" onClick={handleEvent}>
                                <i className="bi bi-gift text-white mb-1"></i>
                                <h6 className="fs-12 text-white">
                                  BZ University good night event in columbia
                                </h6>
                              </div>
                              <div className="text-center text-white bg-success shadow-sm rounded-3 p-2">
                                <MicExternalOnIcon className="mb-1" />
                                <h6 className="fs-12 text-white">
                                  The 3rd International Conference 2020
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InstituteProfile;
