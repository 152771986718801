import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { Popup } from "layout/Popup";
import { useGlobalContext } from "global/context";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

const fontFamily="'Quicksand',sans-serif"
function AdminSignup() {
  const { api } = useGlobalContext();
  const navigate = useNavigate();

  const initialValues = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
  };

  console.log("Adimn : ",initialValues)

  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    role: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values) => {
    if (values.role === "") {
      Popup("Please select a role.");
      return;
    }

    try {
      const endpoint = values.role === "superadmin" ? "/admin/auth/signup" : "/admin/auth/register";
      await api.post(endpoint, values);
      navigate("/auth/admin/login");
      Popup("success", "Successfully created.");
    } catch (error) {
      if (error.response.status === 403) {
        Popup("error", "This email already exists.");
      } else {
        Popup("error", "Error during signup.");
      }
    }
  };

  return (
    <Container fluid className="p-5">
      <Row className="justify-content-md-center">
        <Col xs={12} md={5} className="text-center">
          <Image
            src="https://img.freepik.com/free-vector/happy-freelancer-with-computer-home-young-man-sitting-armchair-using-laptop-chatting-online-smiling-vector-illustration-distance-work-online-learning-freelance_74855-8401.jpg?w=740&t=st=1720676868~exp=1720677468~hmac=4987bc80f01dc7c0c9f909788656865c1e4f8e49bf780c9d5dee526e09c88017"
            fluid
            rounded
            className="mb-4"
          />
        </Col>
        <Col xs={12} md={5}>
          <Typography variant="h4" gutterBottom textAlign="center" color="primary" fontWeight="bold" fontFamily={fontFamily}>
            Admin Signup
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <TextField
                    label="Username"
                    name="username"
                    fullWidth
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username && errors.username}
                    variant="outlined"
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    variant="outlined"
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    label="Password"
                    name="password"
                    type="password"
                    fullWidth
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    variant="outlined"
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    fullWidth
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    variant="outlined"
                  />
                </Box>
                <Box mb={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Role</InputLabel>
                    <Select
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.role && Boolean(errors.role)}
                    >
                      <MenuItem value="">Select a Role</MenuItem>
                      <MenuItem value="superadmin">Superadmin</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                      {/* <MenuItem value="sub-admin">Sub-Admin</MenuItem> */}
                    </Select>
                    {touched.role && errors.role && (
                      <div style={{ color: "red", marginTop: "5px" }}>{errors.role}</div>
                    )}
                  </FormControl>
                </Box>
                <Box textAlign="center">
                  <Button variant="contained" color="primary" type="submit" sx={{fontFamily:fontFamily}}>
                    Signup
                  </Button>
                </Box>
                <p className="text-center mt-3">
                  Already have an account?{" "}
                  <strong>
                    <Link to="/auth/admin/login">Login</Link>
                  </strong>
                </p>
              </form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminSignup;
