import React, { useEffect, useState } from 'react'
import moment from 'moment'
import ConnectionRequestItem from './ConnectionRequestItem'
import ConnectionProfile from '../components/ConnectionProfile'
import { useGlobalContext } from 'global/context'
import { useOutletContext } from 'react-router-dom'
import { Divider } from '@mui/material';
import { Popup } from "layout/Popup";

var removeByAttr = function (arr, attr, value) {
    var i = arr.length;
    while (i--) {
        if (arr[i]
            && arr[i].hasOwnProperty(attr)
            && (arguments.length > 2 && arr[i][attr] === value)) {

            arr.splice(i, 1);

        }
    }
    return arr;
}

const ConnectionTab = ({ profile }) => {
    const { userData, api, apiAuth, onlineUsers } = useGlobalContext();
    const [pendingRequestsReceived, setPendingRequestsReceived] = useState([]);
    const [pendingRequestsSent, setPendingRequestsSent] = useState([]);
    const [connections, setConnections] = useState([]);
    const [showReceivedRequests, setShowReceivedRequests] = useState(false);
    const [showSentRequests, setShowSentRequests] = useState(false);


    // const getPendingConnections = async () => {
    //     try {
    //         const receivedRes = await api.get(`/app/connections/received-pending?userId=${profile?.id ? profile?.id : userData?.id}`);
    //         const sentRes = await api.get(`/app/connections/sent-pending?userId=${profile?.id ? profile?.id : userData?.id}`);

    //         if (receivedRes?.status === 200) {
    //             console.log('Pending connection : ', receivedRes?.data);
    //             setPendingRequestsReceived(receivedRes?.data?.rows);
    //         }

    //         if (sentRes?.status === 200) {
    //             console.log('Pending connection : ', sentRes?.data);
    //             setPendingRequestsSent(sentRes?.data?.rows);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    const getPendingConnectionsREC = async () => {
        try {
            const receivedRes = await api.get(`/app/connections/received-pending?userId=${profile?.id ? profile?.id : userData?.id}`);
            if (receivedRes?.status === 200) {
                console.log('Pending connection : ', receivedRes?.data);
                setPendingRequestsReceived(receivedRes?.data?.rows);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const getPendingConnectionsSEN = async () => {
        try {
            const sentRes = await api.get(`/app/connections/sent-pending?userId=${profile?.id ? profile?.id : userData?.id}`);
            if (sentRes?.status === 200) {
                console.log('Pending connection : ', sentRes?.data);
                setPendingRequestsSent(sentRes?.data?.rows);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getConnections = async () => {
        try {
            const res = await api.get(`/app/connections?userId=${profile?.id ? profile?.id : userData?.id}`);
            if (res?.status === 200) {
                console.log('My Connections:', res?.data);

                // Assuming `res?.data?.connections` is an array of connection objects with `id`
                const updatedConnections = res?.data?.connections.map((connection) => {
                    // Check if the connection's ID is in the onlineUsers list
                    console.log("Online : ", onlineUsers)
                    const isOnline = onlineUsers.some((user) => user.userData.id === connection.id);
                    return { ...connection, isOnline };
                });

                setConnections(updatedConnections); // Update the state with online status added
            }
        } catch (e) {
            console.log(e);
            Popup("error", e?.response?.data?.message);
        }
    };

    // const removeRequest = (requestId, requestType) => {
    //     if(requestType === 'received'){
    //     setPendingRequests(removeByAttr(pendingRequests, 'id', requestId));
    //     getPendingConnections();
    //     getConnections();
    // }
    const removeRequest = (requestId, requestType) => {
        if (requestType === 'received') {
            setPendingRequestsReceived(pendingRequestsReceived.filter(request => request.id !== requestId));
            getConnections();
        } else if (requestType === 'sent') {
            setPendingRequestsSent(pendingRequestsSent.filter(request => request.id !== requestId));
            getConnections();
        }
    }

    useEffect(() => {
        getPendingConnectionsREC();
        getPendingConnectionsSEN();

        getConnections();
    }, [userData, profile]);

    console.log("Received : ", pendingRequestsReceived)
    console.log("Sent : ", pendingRequestsSent)



    return (
        <>
            <div className="">
                <div>
                    {/*start Connection Request come */}
                    {
                        profile?.id && profile?.id !== userData?.id ?
                            null : (
                            <div className="user-panel mb-40px">
                                <div className="mb-4 d-flex align-items-center justify-content-between">
                                    <h3 className="fs-20 fw-bold">Connection Requests Received</h3>
                                    <button type="button" className="btn btn-primary" data-toggle="button" aria-pressed="false" autoComplete="off"
                                        onClick={() => setShowReceivedRequests(!showReceivedRequests)}>
                                        {showReceivedRequests ? 'Hide' : 'Show'}
                                    </button>
                                </div>
                                {showReceivedRequests && (
                                    <div className="">
                                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                                            {pendingRequestsReceived.length ? (
                                                pendingRequestsReceived.map(request => (
                                                    <div className="p-3">
                                                        <ConnectionRequestItem key={request.id} user={request} removeRequest={removeRequest} requestType="received" />
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="p-3">
                                                    <h5 className='text-center text-secondary rounded-4 p-5' style={{ border: "1px dashed lightgrey" }}>No Pending Requests</h5>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            )
                    }
                    {/* end Connection Request come */}
                </div>
                <Divider sx={{ my: 2 }} />
                <div>
                    {/* connection request sent start*/}

                    {
                        profile?.id && profile?.id !== userData?.id ?
                            null :
                            <div className="user-panel mb-40px">
                                <div className="mb-4 d-flex align-items-center justify-content-between">
                                    <h3 className="fs-20 fw-bold">Connection Requests Sent</h3>
                                    <button type="button" className="btn btn-primary" data-toggle="button" aria-pressed="false" autoComplete="off"
                                        onClick={() => setShowSentRequests(!showSentRequests)}>
                                        {showSentRequests ? 'Hide' : 'Show'}
                                    </button>
                                </div>
                                {showSentRequests && (

                                    <div className="">
                                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                                            {pendingRequestsSent.length ? (
                                                pendingRequestsSent.map(request => (
                                                    <div className="p-3">
                                                        <ConnectionRequestItem key={request.id} user={request} removeRequest={removeRequest} requestType="sent" />
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="p-3">
                                                    <h5 className='text-center text-secondary rounded-4 p-5' style={{ border: "1px dashed lightgrey" }}>No Pending Requests</h5>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                    }

                    {/* connection request sent end */}
                </div>
                <Divider sx={{ my: 2 }} />
                <div>
                    {/* Connection Request */}
                    <div className="user-panel mb-40px">
                        <div className=" mb-3 d-flex align-items-center justify-content-between">
                            <h3 className="fs-20 fw-bold text-capitalize">
                                {
                                    profile?.id ?
                                        `${profile?.first_name}'s Connections`
                                        : "My Connections"
                                }
                            </h3>
                        </div>
                        <div className="summary-panel ">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2">
                                {
                                    connections?.length ?
                                        connections?.map((connection, index) => (
                                            <div className="p-3">
                                                <ConnectionProfile user={connection} key={index} />
                                            </div>
                                        ))
                                        :
                                        <div className="p-3">
                                            <h5 className='text-center text-secondary rounded-4 p-5'
                                                style={{
                                                    border: "1px dashed lightgrey",
                                                }}
                                            >No Connections</h5>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* end Connection Request */}
                </div>
            </div>
        </>
    )
}

export default ConnectionTab