import React, { useState } from "react";
import { useGlobalContext } from "global/context";


const Event = () => {
    const { userData, api } = useGlobalContext();
    const [programs, setPrograms] = useState([
        { name: "", date: "", startTime: "", endTime: "" },
    ]);

    const initialFormData = {
        instituteId: userData?.id,
        instituteName: "" || userData?.name,
        title: "",
        description: "",
        coordinatorName: "",
        email: "",
        contactNumber: "",
        eventStartDate: "",
        eventEndDate: "",
        registrationLink: "",
        location: "",
        chiefGuest: "",
        fees: "",
        award: "",
        remarks: "",
    };

    const [formData, sethtmlFormData] = useState(initialFormData);
    const handleAddProgram = () => {
        setPrograms([...programs, { name: "", date: "", startTime: "", endTime: "" }]);
    };

    const handleProgramChange = (index, field, value) => {
        const updatedPrograms = programs.map((program, i) =>
            i === index ? { ...program, [field]: value } : program
        );
        setPrograms(updatedPrograms);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        sethtmlFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData || !programs) {
            console.error("Form data or programs are missing.");
            return;
        }

        const dataToSend = {
            ...formData,
            programs,
        };

        // console.log("Event data : ", dataToSend)
        try {
            const res = await api.post(`/app/events/postEvents`, dataToSend);
            if (res?.status === 201) {
                console.log("message : ",res.data)
                console.log(res.data.message);
                // Optional: Provide user feedback on success
            }
        } catch (error) {
            console.error("Error posting event:", error.response ? error.response.data : error.message);
            // Optional: Show user feedback in case of an error
        }
        sethtmlFormData(initialFormData)
    }
    return (
        <>
            <form className="p-3 border-2 border-black dark:border-gray-600 rounded-xl" onSubmit={handleSubmit}>
                <div className="max-h-[600px] overflow-y-auto hide-scrollbar">
                    <div className="border border-gray-300 rounded-xl shadow-lg p-6">
                        <h2 className="text-xl font-semibold text-center text-gray-800 dark:text-gray-200 mb-4 border-b border-gray-300 pb-2">
                            Organize A Event
                        </h2>
                        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">

                            <div className="md:w-1/2">
                                <label htmlFor="instituteName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Institute Name</label>
                                <div className="flex">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                        </svg>
                                    </span>
                                    <input type="text" name="instituteName" value={formData.instituteName} onChange={handleInputChange} className="rounded-none rounded-e-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Institute Name" required />
                                </div>
                            </div>

                            <div className="md:mt-0 md:w-1/2">
                                <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                                <input type="text" name="title" value={formData.title} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Title" required />
                            </div>
                        </div>
                        <div className="mt-3">
                            <label htmlFor="description" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                            <textarea id="description" name="description" rows="5" value={formData.description} onChange={handleInputChange} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your message..."></textarea>
                        </div>
                        <div className="mt-3">
                            <h2 className="text-xl font-bold">Cordinator Details </h2>
                            <div className="flex flex-col md:flex-row md:space-x-4">
                                <div className="md:w-1/3">
                                    <label htmlFor="coordinatorName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Coordinator Name
                                    </label>
                                    <div className="flex">
                                        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                            <svg
                                                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                            >
                                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                            </svg>
                                        </span>
                                        <input
                                            type="text"
                                            name="coordinatorName"
                                            value={formData.coordinatorName}
                                            onChange={handleInputChange}
                                            className="rounded-none rounded-e-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Cordinator Name" required
                                        />
                                    </div>
                                </div>

                                <div className="md:w-1/3 md:mt-0">
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Email Id
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                        placeholder="abc@gmail.com"
                                        required
                                    />
                                </div>

                                <div className="md:w-1/3 md:mt-0">
                                    <label htmlFor="contactNumber" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Contact Number
                                    </label>
                                    <input
                                        type="tel"
                                        name="contactNumber"
                                        value={formData.contactNumber}
                                        onChange={handleInputChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Enter contact number"
                                        pattern="[0-9]{10}"
                                    />
                                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                                        Enter a 10-digit contact number.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <h2 className="text-xl font-bold mb-2">Event Programs</h2>

                            {programs.map((program, index) => (
                                <div key={index} className="mb-2 space-y-3 md:space-y-3 md:space-y-0 md:flex md:items-center md:space-x-4">
                                    {/* Row 1: Program Name and Date */}
                                    <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-x-3 md:flex-1">
                                        <span className="text-sm font-medium w-6">{index + 1}.</span>
                                        <input
                                            type="text"
                                            value={program.name}
                                            onChange={(e) => handleProgramChange(index, "name", e.target.value)}
                                            className="p-2 border rounded-lg flex-1"
                                            placeholder="Program Name"
                                        />
                                        <input
                                            type="date"
                                            value={program.date}
                                            onChange={(e) => handleProgramChange(index, "date", e.target.value)}
                                            className="p-2 border rounded-lg w-full md:w-auto"
                                        />
                                    </div>

                                    {/* Row 2: Start Time, End Time, and Add Button in a Single Row */}
                                    <div className="flex items-center space-x-3">
                                        <input
                                            type="time"
                                            value={program.startTime}
                                            onChange={(e) => handleProgramChange(index, "startTime", e.target.value)}
                                            className="p-2 border rounded-lg w-full md:w-auto"
                                        />
                                        <input
                                            type="time"
                                            value={program.endTime}
                                            onChange={(e) => handleProgramChange(index, "endTime", e.target.value)}
                                            className="p-2 border rounded-lg w-full md:w-auto"
                                        />
                                        <button
                                            type="button"
                                            onClick={handleAddProgram}
                                            className="p-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>


                        <div className="mb-3">
                            <h3 className="text-xl font-bold text-gray-700 dark:text-gray-300">Event Date's</h3>

                            <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <div className="md:w-1/2">
                                    <label htmlFor="eventStartDate" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Start Date
                                    </label>
                                    <input
                                        type="date"
                                        name="eventStartDate"
                                        value={formData.eventStartDate}
                                        onChange={handleInputChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                    />
                                </div>

                                <div className="md:mt-0 md:w-1/2">
                                    <label htmlFor="eventEndDate" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                                        End Date
                                    </label>
                                    <input
                                        type="date"
                                        name="eventEndDate"
                                        value={formData.eventEndDate}
                                        onChange={handleInputChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row md:items-center md:space-x-4 md:mb-3">
                            <div className="md:w-1/2">
                                <label htmlFor="registrationLink" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Registration/Meeting Link
                                </label>
                                <input
                                    type="url"
                                    name="registrationLink"
                                    value={formData.registrationLink}
                                    onChange={handleInputChange}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="https://example.com"
                                />
                                <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                                    Please enter a valid URL (e.g., https://example.com)
                                </p>
                            </div>
                            <div className="md:mt-0 md:w-1/2">
                                <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" >Upload file</label>
                                <input id="image" name="image" type="file" className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" />
                                <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">Upload a Template / banner of your Event</div>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="remarks" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Remarks </label>
                            <textarea id="remarks" name="remarks" rows="4" value={formData.remarks} onChange={handleInputChange} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Details ... "></textarea>
                        </div>
                        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                            <div className="md:mt-0 md:w-1/2">
                                <label htmlFor="location" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">Location</label>
                                <input
                                    type="text"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleInputChange}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                    placeholder="Event Location"
                                    required
                                />
                            </div>

                            <div className="md:mt-0 md:w-1/2">
                                <label htmlFor="chiefGuest" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Chief Guest</label>
                                <div className="flex">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                        </svg>
                                    </span>
                                    <input
                                        type="text"
                                        name="chiefGuest"
                                        value={formData.chiefGuest}
                                        onChange={handleInputChange}
                                        className="rounded-none rounded-e-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Chief Guest Name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                            <div className="md:w-1/2">
                                <label htmlFor="fees" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Fees
                                </label>
                                <input
                                    type="number"
                                    name="fees"
                                    value={formData.fees}
                                    onChange={handleInputChange}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Enter fees amount"
                                />
                            </div>
                            <div className="md:mt-0 md:w-1/2">
                                <label htmlFor="award" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Award
                                </label>
                                <input
                                    type="text"
                                    name="award"
                                    value={formData.award}
                                    onChange={handleInputChange}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Enter award"
                                />
                            </div>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button
                                type="submit"
                                className="px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <style jsx>{`
                .hide-scrollbar {
                    scrollbar-width: none; /* Firefox */
                    -ms-overflow-style: none; /* Internet Explorer and Edge */
                }
                .hide-scrollbar::-webkit-scrollbar {
                    display: none; /* Safari and Chrome */
                }
            `}</style>
        </>
    );
};

export default Event;
