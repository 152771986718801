import { Box, Avatar } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from 'global/context';
import classNames from 'classnames';

// Styled component with media queries for mobile and desktop
const MainChatContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 84vh;
  padding: 5px;

  @media (max-width: 768px) {
    width:90vw;
    height:80vh;
    padding:0;
  }

  @media (max-width: 600px) {
    width:90vw;
    height:80vh;
    padding:0;
  }

  .chat-box {
    flex-grow: 1;
    overflow-y: auto;
    height: 600px;

    @media (max-width: 768px) {
      height: 80vh;
    }

    @media (max-width: 600px) {
      height: 80vh;
    

    &::-webkit-scrollbar {
      display: none; // Hide scrollbar for webkit browsers
    }
  }
  }

  .message-container {
    width: 50vw;
    margin-bottom: 12px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    position: relative;

    @media (max-width: 768px) {
      width: 70vw; /* Reduce width for mobile view */
      padding: 8px; /* Reduce padding for mobile */
    }

    @media (max-width: 600px) {
      width: 70vw; /* Full width for smaller screens */
      padding: 5px; /* Further reduced padding for smaller screens */
    }
    .message-text {
      font-size: 14px;

      @media (max-width: 768px) {
        font-size: 12px; /* Smaller font size for mobile view */
      }

      @media (max-width: 600px) {
        font-size: 10px; /* Even smaller font for small screens */
      }
    }

    .message-details {
      font-size: 12px;

      @media (max-width: 768px) {
        font-size: 10px; /* Smaller font size for message details */
      }

      @media (max-width: 600px) {
        font-size: 8px; /* Further reduced font size */
      }
    }

    .avatar {
      width: 50px;
      height: 50px;

      @media (max-width: 768px) {
        width: 40px;
        height: 40px; /* Smaller avatar for mobile */
      }

      @media (max-width: 600px) {
        width: 35px;
        height: 35px; /* Smallest avatar for small screens */
      }
    }
  }


  .sender-message {
    left : 2px;
    @media (max-width: 768px) {
      margin-right: 5px; 
      right: 1 /* Reduce margin for mobile */
    }

    @media (max-width: 600px) {
      margin-right: 2px; /* Further reduce margin for smaller screens */
    }
  }

  .receiver-message {
  

    @media (max-width: 768px) {
    
      right: auto;
      margin-left: 5px; 
      left: 26vw; /* Reduce margin for mobile */
    }

    @media (max-width: 600px) {
      right: auto;
      margin-left: 4px; 
      left: 25vw; /* Further reduce margin for smaller screens */
    }
  }
`;

const UserMainChat = ({ sender = [], pd, roomData, handleSendMsg, allMsg, handleReply, msg, setMsg }) => {
  const { userData, api, showMessage, setShowMessage, socket } = useGlobalContext();
  const [isMobile, setIsMobile] = useState(false);
  const messageContainerRef = useRef(null);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [allMsg, messageContainerRef]);// Scroll when the message list updates

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set mobile view for screen width 768px or less
    };

    // Set the initial state
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleChange = (e) => {
    console.log(e.target.value)
    setMsg(e.target.value);
  };
  // console.log("sender : ", sender)
  // console.log("sender pd : ", pd)
  // console.log("sender : ", roomData?.user?.first_name)
  // console.log("sender : ", roomData?.user?.avatar_url)

  
  return (
    <MainChatContainer>
      {roomData ? (
        <div
          className={!isMobile ? 'detailed-view p-3 shadow-sm rounded-3 border border-success flex flex-col' : 'detailed-view p-1 shadow-sm rounded-3 border border-success flex flex-col '}
          style={{ height: isMobile ? '95vh' : '84vh' }}>
          <div className="chat-box" ref={messageContainerRef}>
            {
              allMsg.map((items, index) => {

                const isCurrentUserReceiver = items.receiver_user_id === userData.id || items.receiver_institute_id === userData.id;

                let senderDetails;
                {/* console.log("Items :",items) */}
                if (items.sender_user_id) {
                  // If the sender is a user, use the sender details
                  senderDetails = {
                    name: items.sender?.first_name + ' ' + items.sender?.last_name,
                    avatar: items.sender?.avatar_url,
                  };
                } else if (items.sender_institute_id) {
                  // If the sender is an institute, use the institute details (either fetched or from pd)
                  senderDetails = {
                    name: roomData?.user?.institute?.name ||  pd.name, // Safely access institute name
                    avatar: "https://icdn.isrgrajan.com/in/2021/05/Gurukul-ancient-indian-school.jpg" || pd.logo,  // Safely access institute logo
                  };
                } else {
                  // Default to current user's data if no sender information is available
                  senderDetails = {
                    name: roomData?.user?.institute?.name || 'Unknown Sender',  // Safely access institute name
                    avatar: roomData?.user?.institute?.logo || 'https://icdn.isrgrajan.com/in/2021/05/Gurukul-ancient-indian-school.jpg',  // Safely access institute logo
                  };
                }

                return (
                  isMobile ? (
                    <div
                      key={index}
                      className={`message-container ${!isCurrentUserReceiver ? 'receiver-message' : 'sender-message'}`}
                    >
                      <div className="d-flex align-items-center">
                        <Avatar
                          alt="Sender Avatar"
                          src={senderDetails.avatar} // Sender's avatar or the current user's logo
                          className=" avatar ml-1 mr-1 mt-2"
                          sx={{ width: '60px', height: '60px' }}
                        />
                        <div className="d-flex flex-column w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-dark mb-1 message-text">
                              <strong>{senderDetails.name}</strong> {/* Sender's name */}
                            </p>
                            <p className="fs-11 message-details text-right mb-0">
                              {new Date(items.time_to_send || items.createdAt).toLocaleString()} {/* Formatted Date */}
                            </p>
                          </div>
                          <p className="fs-14 mb-0 ml-2 mt-0 message-text">
                            {items.message && items.message.includes('\n') ? items.message.split('\n')[0] : ' '}
                          </p>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="message-text">
                          {/* If there's a newline, print the part after it; otherwise, print the whole message as details */}
                          {items.message && items.message.includes('\n') ? items.message.split('\n')[1] : items.message || items.msg}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className={`message-container ${isCurrentUserReceiver ? 'mr-auto' : 'ml-auto'}`}
                    >
                      <div className="d-flex align-items-center">
                        <Avatar
                          alt="Sender Avatar"
                          src={senderDetails.avatar} // Sender's avatar or the current user's logo
                          className="  mr-3"
                          sx={{ width: '60px', height: '60px' }}
                        />
                        <div className="d-flex flex-column w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-dark mb-1 ">
                              <strong>{senderDetails.name}</strong> {/* Sender's name */}
                            </p>
                            <p className="fs-11 text-right mb-0">
                              {new Date(items.time_to_send || items.createdAt).toLocaleString()} {/* Formatted Date */}
                            </p>
                          </div>
                          <p className="fs-14 mb-0">
                            {items.message && items.message.includes('\n') ? items.message.split('\n')[0] : ' '}
                          </p>
                        </div>
                      </div>
                      <div className="mt-3">
                        <p className="mb-1">
                          <strong>Details:</strong>
                        </p>
                        <p >
                          {/* If there's a newline, print the part after it; otherwise, print the whole message as details */}
                          {items.message && items.message.includes('\n') ? items.message.split('\n')[1] : items.message || items.msg}
                        </p>
                      </div>
                    </div>
                  )
                )
              })
            }

          </div>
          <div className="w-full">
            <div className="flex items-center">
              <input
                className="w-full bg-transparent placeholder:text-green-400 text-green-700 text-sm border border-green-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-green-500 hover:border-green-300 shadow-sm focus:shadow"
                placeholder="Type here..."
                value={msg}
                onChange={handleChange}
              />
              <button
                className="ml-2 rounded bg-slate-800 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
                onClick={handleReply}
              >
                Reply
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="chat-page relative w-full h-[80vh] bg-[#fafafa] flex justify-center items-center">
          <div className="flex flex-col items-center justify-center w-full h-full bg-white rounded-3">
            <img className="w-[50px] h-[50px] rounded-full" src={pd?.avatar_url} alt="User profile" />
            <h3 className="text-[#111b21] text-[20px] font-medium tracking-wide text-center">
              Welcome{' '}
              <span className="text-[#166e48] text-[19px] font-bold">{pd.name}</span>
            </h3>
          </div>
        </div>
      )}
    </MainChatContainer>
  );
};

export default UserMainChat;
