import { Add, CheckCircle, CheckCircleTwoTone, Circle, DeselectRounded, HourglassTopTwoTone, Image, MailTwoTone, PendingActions, PendingActionsTwoTone, PersonAddAlt, PersonAddAltSharp, PunchClockTwoTone, SmsFailedRounded, StarRounded } from '@mui/icons-material'
import { Avatar, Divider, IconButton, Tooltip } from '@mui/joy'
import { Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { useGlobalContext } from 'global/context'
import { Popup } from 'layout/Popup'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Geocode from "react-geocode"
import { toast } from 'react-hot-toast'
import useDidMountEffect from 'global/useDidMountEffect'
// import useCurrentLocation from 'global/useCurrentLocation'
import styled from 'styled-components';

// A styled button that will appear on small screens
const FloatingButton  = styled.button`
position: fixed;
bottom: 20px;
right: 10px;
background: linear-gradient(145deg, #ffffff, #e6e6e6); /* Light gradient for depth */
color: black;
border: none; /* Remove the default border */
border-radius: 8px; /* Softer corners */
padding: 7px; /* Ample padding for a larger button */
font-size: 12px; /* Increased font size for better visibility */
font-weight: 600;
z-index: 1000;
display: none; /* Initially hidden */
cursor: pointer; /* Change cursor to pointer */

/* 3D effect with shadows on both sides */
box-shadow: 
  6px 6px 15px rgba(0, 0, 0, 0.2), /* Strong bottom right shadow */
  -4px -4px 10px rgba(255, 255, 255, 0.9), /* Strong top left highlight */
  0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle depth shadow */

@media (max-width: 992px) {
  display: block; /* Show button on screens 992px or less */
}

&:hover {
  box-shadow: 
    8px 8px 20px rgba(0, 0, 0, 0.3), /* Enhanced bottom right shadow */
    -6px -6px 15px rgba(255, 255, 255, 0.9), /* Enhanced top left highlight */
    0 3px 8px rgba(0, 0, 0, 0.2); /* Slightly stronger depth shadow */
  transform: translateY(-4px); /* Lift the button slightly on hover */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition */
}

&:active {
  box-shadow: 
    inset 4px 4px 10px rgba(0, 0, 0, 0.5), /* Inset shadow when active */
    inset -4px -4px 10px rgba(255, 255, 255, 0.5); /* Inset highlight */
  transform: translateY(0); /* Reset the lift effect */
}
`;


// The overlay background (glass effect)
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Glass-like blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

// The modal container for SuggestedExperts
const ModalContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 80vh;
  overflow-y: auto; /* Scrollable content inside the modal */
`;

// A close button inside the modal
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

const ExpertRow = ({ data, index }) => {
  const { userData, api, setShowMessage, setMessageTo, setAuth, users } =
    useGlobalContext();
  const handleConnect = async () => {
    try {
      const res = await api.post("/app/connections/send-request", {
        senderId: userData?.id,
        receiverId: data?.id,
      });
      if (res?.status === 200) {
        data = { ...data, connectionStatus: "pending" };
        toast.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        setAuth(true);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleMessage = () => {
    setShowMessage(true);
    setMessageTo(data);
  };

  return (
    <>
      <div className="d-flex rounded p-2 align-items-center hover-bg mb-3">
        <ListItemAvatar>
          <Avatar src={data?.avatar_url} sx={{ height: 40, width: 40 }} />
        </ListItemAvatar>
        <ListItemText
          className=" pt-0 m-0"
          primary={
            <div style={{ lineHeight: "20px" }}>
              <p className="text-dark fw-bold">
                <Link to={`/${data?.username}`}>
                  {data?.first_name} {data?.last_name}
                </Link>
                {/* <span><Circle
                                // color='success'
                                style={{ fontSize: '10px', color: 'lightgreen' }}
                            /> </span> */}
              </p>
              {data?.skill_name?.map(
                (skill, index) =>
                  index < 2 && (
                    <p
                      className="fw-bold text-info d-flex align-items-center"
                      style={{ fontSize: "14px" }}
                      key={index}
                    >
                      <StarRounded
                        style={{
                          fontSize: "14px",
                          color: "gold",
                          marginLeft: -3,
                        }}
                      />
                      {skill}
                    </p>
                  )
              )}
            </div>
          }
        />
        <Tooltip
          arrow
          placement="right"
          color={
            data?.connectionStatus === "accepted"
              ? "info"
              : data?.connectionStatus === "pending"
              ? "warning"
              : data?.connectionStatus === "rejected"
              ? "danger"
              : "primary"
          }
          variant="soft"
          title={
            `data?.connectionStatus === "accepted"
              ? Message to ${data?.first_name}
              : data?.connectionStatus === "pending"
              ? "Your connection request is pending"
              : data?.connectionStatus === "rejected"
              ? Your connection request is rejected by ${data?.first_name}
              : Send connection request to ${data?.first_name}`
          }
        >
          <IconButton
            color={
              `data?.connectionStatus === "accepted"
                ? "info"
                : data?.connectionStatus === "pending"
                ? "warning"
                : data?.connectionStatus === "rejected"
                ? "danger"
                : "primary"`
            }
            onClick={
              data?.connectionStatus === "accepted"
                ? handleMessage
                : data?.connectionStatus === "pending"
                ? null
                : handleConnect
            }
          >
            {data?.connectionStatus === "accepted" ? (
              <MailTwoTone />
            ) : data?.connectionStatus === "pending" ? (
              <HourglassTopTwoTone />
            ) : data?.connectionStatus === "rejected" ? (
              <SmsFailedRounded />
            ) : (
              <PersonAddAltSharp />
            )}
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

const SuggestedExperts = (props) => {
  const { api, userData } = useGlobalContext();
  const [heading, setHeading] = useState(props?.heading || "Suggested Experts");
  const [suggestedExperts, setSuggestedExperts] = useState([]);
  let [administrativeAreaLevel1, setAdministrativeAreaLevel1] = useState("");
  let [administrativeAreaLevel3, setAdministrativeAreaLevel3] = useState("");
  let [country, setcountry] = useState("");
  let [postalCode, setPostalcode] = useState();
  const [coords, setCoords] = useState({});
  const [addres, setAddres] = useState("");
  const [locality, setLocality] = useState(props?.location);
  const [subLocality, setSubLocality] = useState(props?.location);
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initialize with current window width

  // const apiKey = "b27e7c8443cb4017afa53857671ff490";
  // const apiKey = 'AIzaSyBODCtNSEPLF1R0J0IVur36-jk0ClKhjRY'
  // const apiKey = "AIzaSyBODCtNSEPLF1R0J0IVur36-jk0ClKhjRY"
  // Geocode.setApiKey(apiKey);
  Geocode.setLanguage("en");
  Geocode.setRegion("IN");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();


  // Function to toggle the modal
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  // Effect to handle window resizing and update windowWidth
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchLatLong = () => {
    window?.navigator?.geolocation?.getCurrentPosition((geolocation) => {
      const coordinates = geolocation?.coords;
      setCoords(coordinates);
      if (coordinates) {
        // console.log("lat : ",coordinates.latitude)
        // console.log("long : ",coordinates.longitude)
        getCityName(coordinates.latitude, coordinates.longitude);
      }
    });
  };

  const getCityName = async (latitude, longitude) => {
    try {
      const response = await api.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
      // console.log("Response : ",response)
      const result = response?.data;
      const city = result?.address?.city || result?.address?.road || result?.address?.state;
      const subLocality = result?.address?.road || result?.neighbourhood || result?.quarter;
      setLocality(subLocality);
      setSubLocality(city);
      
    } catch (error) {
      console.log("Error getting city name: ", error);
    }
  };

  const getSuggestedExperts = async () => {
   
    try {
      const res = await api.post("/app/user/suggested/experts", {
        userId: userData?.id,
        skill: props?.skill,
        location: subLocality || locality,
      });
      if (res?.status === 200) {
        // console.log("Suggested Experts: ", res?.data);
        setSuggestedExperts(res?.data?.results);
      }
    } catch (error) {
      console.log(error);
      Popup('error', error?.response?.data?.message);
      window.location.reload(true);
    }
  };

  useDidMountEffect(() => {
    // console.log({ locality, subLocality });
    getSuggestedExperts();
  }, [subLocality, locality]);

  useEffect(() => {
    fetchLatLong();
    getSuggestedExperts();
  }, []);
  return (
    <>
     {windowWidth > 992 ? (
      <div className="p-2 p-lg-3 rounded shadow-lg mb-3">
      <div className="d-flex justify-content-between">
        <h5>{heading}</h5>
        <Link to={`/users`}>See All</Link>
      </div>
      <p>
        Based on location (<a>{subLocality || locality}</a>)
      </p>
      <List className="mt-2" sx={{ width: "100%", bgcolor: "light", p: 0 }}>
        {suggestedExperts?.map((data, index) => (
          <ExpertRow data={data} index={index} key={index} />
        ))}
      </List>
    </div>
    ):(
      <>
        {/* Floating button for small screens */}
        <FloatingButton className="mb-10" onClick={toggleOpen}>Nearest Gyani's</FloatingButton>

        {/* Overlay for the popup */}
        <Overlay isOpen={isOpen}>
          <ModalContainer>
            <CloseButton onClick={toggleOpen}>&times;</CloseButton>
            
    <div className="p-2 p-lg-3 rounded shadow-lg mb-3">
      <div className="d-flex justify-content-between">
        <h5>{heading}</h5>
        <Link to={`/users`}>See All</Link>
      </div>
      <p>
        Based on location (<a>{subLocality || locality}</a>)
      </p>
      <List className="mt-2" sx={{ width: "100%", bgcolor: "light", p: 0 }}>
        {suggestedExperts?.map((data, index) => (
          <ExpertRow data={data} index={index} key={index} />
        ))}
      </List>
    </div>
    </ModalContainer>
    </Overlay>
        </>
      )}
    </>
  );
};

export default SuggestedExperts;