import { Favorite, HourglassTopTwoTone, MailTwoTone, PersonAddAltRounded, SmsFailedRounded, Star } from '@mui/icons-material';
import { Avatar, Button, IconButton, Chip } from '@mui/joy';
import { Rating } from '@mui/material';
import { useGlobalContext } from 'global/context';
import React from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

const EventCard = ({ onlyShow, events }) => {
  // console.log("events ==== > ", events)
  const navigate = useNavigate();
  const { userData, api, setShowMessage, setMessageTo, setAuth } = useGlobalContext();

  // Calculate days difference
  const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Math.ceil((end - start) / (1000 * 60 * 60 * 24) + 1); // Difference in days
  };

  const daysDifference = calculateDaysDifference(
    events?.eventStartDate,
    events?.eventEndDate
  );


  return (
    <div className="col mb-3">
      <div className="relative group w-70 h-[400px]"> {/* Fixed height */}
        {/* Background Design */}
        <div className="absolute inset-0">
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 opacity-20 rounded-lg group-hover:opacity-30 transition-opacity duration-300"></div>

          {/* Wave Pattern */}
          <svg
            className="absolute bottom-0 left-0 w-full h-24 text-white opacity-30"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="currentColor"
              d="M0,256L48,245.3C96,235,192,213,288,202.7C384,192,480,192,576,213.3C672,235,768,277,864,282.7C960,288,1056,256,1152,234.7C1248,213,1344,203,1392,197.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>

        {/* Main Card */}
        <div className="relative bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 p-4 h-full flex flex-col justify-between">
          {/* Card Header with Background Image */}
          <div
            className="relative h-32 flex items-center justify-center text-white text-2xl font-bold"
            style={{
              position: "relative",
              backgroundColor: "#1a73e8",
              borderRadius: "8px",
              overflow: "hidden",
              boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
            }}
          >
            {/* 3D Calendar Icon */}
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "20%",
                transform: "translate(-50%, -50%) rotateX(20deg) rotateY(-20deg)",
                width: "80px",
                height: "80px",
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "#1a73e8",
                fontWeight: "bold",
                zIndex: 1,
              }}
            >
              {events?.eventStartDate && (
                <>
                  <div
                    style={{
                      backgroundColor: "#1a73e8",
                      color: "#ffffff",
                      width: "100%",
                      textAlign: "center",
                      padding: "4px 0",
                      fontSize: "12px",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                  >
                    {new Date(events.eventStartDate).toLocaleString("default", { month: "short" }).toUpperCase()}
                  </div>
                  <div style={{ fontSize: "28px", lineHeight: "40px" }}>
                    {new Date(events.eventStartDate).getDate()}
                  </div>
                </>
              )}
            </div>


            {/* Event Title */}
            <span
              className="absolute z-10"
              style={{
                color: "#ffffff",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                padding: "4px 12px",
                borderRadius: "5px",
                top: "50%",
                left: "55%",
                transform: "translateY(-50%)",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {events.title || "Event Name"}
            </span>

            {/* Light Glow Animation */}
            <div
              style={{
                position: "absolute",
                inset: 0,
                background: "linear-gradient(45deg, #1a73e8, #6a88e8, #9db5e8)",
                opacity: 0.5,
                filter: "blur(15px)",
                animation: "glow 3s infinite alternate",
                zIndex: 0,
              }}
            ></div>

            <style>
              {`
      @keyframes glow {
        0% {
          opacity: 0.5;
        }
        100% {
          opacity: 0.8;
        }
      }
    `}
            </style>
          </div>


          {/* Event Details */}
          <div className="text-gray-600 space-y-2">
            <p>
              <strong>Organized By:</strong> {events.instituteName}
            </p>
            <p>
              <strong>Location:</strong> {events.location}
            </p>

            {/* Programs */}
            <div>
              <strong>Programs:</strong>{" "}
              {events.programs.map((program, index) => (
                <span
                  key={index}
                  className="inline-block bg-blue-100 text-blue-800 rounded-full px-2 py-1 text-xs font-semibold mr-2 mb-1 group-hover:bg-blue-200"
                >
                  {program.program}
                </span>
              ))}
            </div>
          </div>

          {/* Event Date */}
          <div className="text-sm text-gray-500">
            <strong> {daysDifference} days Program </strong>
          </div>

          {/* Interactive Button */}
          <button className="mt-4 w-full py-2 bg-blue-500 text-white rounded-md font-semibold text-sm hover:bg-blue-600 transition-colors duration-300"
            onClick={() => navigate(`/event/${events.id}`)}>
            I am Interested
          </button>
        </div>
      </div>
    </div>
  )
}

export default EventCard;