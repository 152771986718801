// import React, { useEffect, useState } from "react";

// const SupadminDashboard = () => {
//   const [dashboardData, setDashboardData] = useState({
//     users: { students: 0, teachers: 0, professionals: 0 },
//     institutes: 0,
//     jobs: { totalJobs: 0, appliedJobs: 0 },
//     questions: 0,
//     answers: 0,
//     comments: 0,
//     perDayLogins: 0,
//     totalOnlineUsers: 0,
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch("/api/superadmin/dashboard");
//         const data = await response.json();
//         setDashboardData(data);
//       } catch (err) {
//         console.error("Error fetching dashboard data:", err);
//       }
//     };

//     fetchData();
//   }, []);

//   const {
//     users,
//     institutes,
//     jobs,
//     questions,
//     answers,
//     comments,
//     perDayLogins,
//     totalOnlineUsers,
//   } = dashboardData;

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-gray-100 via-blue-50 to-purple-100 p-6">
//       {/* <h1 className="text-4xl font-extrabold text-gray-800 text-center mb-10">
//         🌟 SuperAdmin Dashboard 🌟
//       </h1> */}
//       <div className="bg-gradient-to-r from-teal-200 to-teal-100 shadow-lg rounded-xl p-4 text-gray-800 hover:scale-105 transform transition w-48 text-center cursor-pointer">
//         <h2 className="text-lg font-semibold mb-2">Add Subjects</h2>
//         <button className="bg-teal-500 text-white px-4 py-2 rounded-full hover:bg-teal-600">
//           Add Subject
//         </button>
//       </div>
//       <div className="bg-gradient-to-r from-indigo-200 to-indigo-100 shadow-lg rounded-xl p-4 text-gray-800 hover:scale-105 transform transition w-48 text-center cursor-pointer">
//         <h2 className="text-lg font-semibold mb-2">Add Class</h2>
//         <button className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600">
//           Add Class
//         </button>
//       </div>
//       <div className="flex flex-wrap gap-6">
//         {/* Users */}
//         <div className="bg-gradient-to-r from-green-400 to-blue-400 shadow-lg rounded-xl p-6 text-white hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Users</h2>
//           <p>🎓 Students: <span className="font-bold">{users.students}</span></p>
//           <p>📚 Teachers: <span className="font-bold">{users.teachers}</span></p>
//           <p>💼 Professionals: <span className="font-bold">{users.professionals}</span></p>
//         </div>

//         {/* Institutes */}
//         <div className="bg-gradient-to-r from-purple-400 to-pink-400 shadow-lg rounded-xl p-6 text-white hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Institutes</h2>
//           <p className="text-3xl font-extrabold">🏫 {institutes}</p>
//         </div>

//         {/* Total Jobs */}
//         <div className="bg-gradient-to-r from-blue-500 to-indigo-500 shadow-lg rounded-xl p-6 text-white hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Jobs</h2>
//           <p>Total: <span className="font-bold">{jobs.totalJobs}</span></p>
//           <p>Applied: <span className="font-bold">{jobs.appliedJobs}</span></p>
//         </div>
//         <div className="bg-gradient-to-r from-red-400 to-pink-400 shadow-lg rounded-xl p-6 text-white hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Online Users</h2>
//           <p className="text-3xl font-extrabold">🌐 {totalOnlineUsers}</p>
//         </div>
//       </div>

//       <div className="flex flex-wrap gap-6">
//         {/* Questions */}
//         <div className="bg-gradient-to-r from-pink-500 to-red-500 shadow-lg rounded-xl p-6 text-white hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Questions</h2>
//           <p className="text-3xl font-extrabold">❓ {questions}</p>
//         </div>

//         {/* Answers */}
//         <div className="bg-gradient-to-r from-yellow-400 to-orange-400 shadow-lg rounded-xl p-6 text-white hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Answers</h2>
//           <p className="text-3xl font-extrabold">💬 {answers}</p>
//         </div>

//         {/* Comments */}
//         <div className="bg-gradient-to-r from-teal-400 to-green-400 shadow-lg rounded-xl p-6 text-white hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Comments</h2>
//           <p className="text-3xl font-extrabold">💡 {comments}</p>
//         </div>
//         <div className="bg-gradient-to-r from-indigo-400 to-blue-400 shadow-lg rounded-xl p-6 text-white hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Daily Logins</h2>
//           <p className="text-3xl font-extrabold">🕒 {perDayLogins}</p>
//         </div>
//       </div>
//       <div className="flex flex-wrap gap-6">
//         {/* Per Day Logins */}
//         <div className="bg-gradient-to-r from-indigo-400 to-blue-400 shadow-lg rounded-xl p-6 text-white hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Daily Logins</h2>
//           <p className="text-3xl font-extrabold">🕒 {perDayLogins}</p>
//         </div>

//         {/* Online Users */}
//         <div className="bg-gradient-to-r from-red-400 to-pink-400 shadow-lg rounded-xl p-6 text-white hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Online Users</h2>
//           <p className="text-3xl font-extrabold">🌐 {totalOnlineUsers}</p>
//         </div>
//         <div className="bg-gradient-to-r from-pink-300 to-pink-100 shadow-lg rounded-xl p-6 text-gray-800 hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Support</h2>
//           <p className="text-3xl font-extrabold">💬 {questions}</p>
//         </div>
//         <div className="bg-gradient-to-r from-teal-200 to-teal-100 shadow-lg rounded-xl p-6 text-gray-800 hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Add Subjects</h2>
//           <p className="text-lg font-semibold">📚 Add new subjects to the system.</p>
//           <button className="bg-teal-500 text-white px-4 py-2 rounded-full hover:bg-teal-600 mt-4">
//             Add Subject
//           </button>
//         </div>
//         <div className="bg-gradient-to-r from-indigo-200 to-indigo-100 shadow-lg rounded-xl p-6 text-gray-800 hover:scale-105 transform transition flex-1 min-w-[200px]">
//           <h2 className="text-xl font-semibold mb-2">Add Class</h2>
//           <p className="text-lg font-semibold">🏫 Add a new class or grade level.</p>
//           <button className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 mt-4">
//             Add Class
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SupadminDashboard;

import React from 'react';
import { Box, Card, Typography, List, ListItem, ListItemText, Avatar } from '@mui/material';
import { Line as ChartJSLine, Doughnut as ChartJSDoughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Legend as ChartJS_Legend,
  Tooltip as ChartJS_Tooltip,
  LineElement,
  PointElement,
  Filler,
  ArcElement,
} from 'chart.js';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  Legend as RechartsLegend
} from 'recharts';


import { useGlobalContext } from "global/context";


// Register the components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  ChartJS_Legend,
  ChartJS_Tooltip,
  LineElement,
  PointElement,
  Filler,
  ArcElement
);

// Sample data
const statisticsData = {
  labels: ['22', '23', '24', '25', '26', '27', '28'],
  datasets: [
    {
      label: 'Users',
      data: [100, 200, 300, 500, 400, 300, 450],
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 2,
    },
  ],
};

const doughnutData = {
  labels: ['Students', 'Teachers', 'Professionals', 'Institutes'],
  datasets: [
    {
      label: 'Service Distribution',
      data: [30, 20, 25, 25], // Service percentages
      backgroundColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(75, 192, 192, 0.6)',
      ],
      borderColor: 'rgba(255, 255, 255, 1)',
      borderWidth: 2,
    },
  ],
};

const data = [
  { month: "Jan", Student: 300, Teacher: 200, Professional: 250, Institutes: 150 },
  { month: "Feb", Student: 280, Teacher: 210, Professional: 260, Institutes: 170 },
  { month: "Mar", Student: 270, Teacher: 230, Professional: 240, Institutes: 180 },
  { month: "Apr", Student: 260, Teacher: 220, Professional: 230, Institutes: 190 },
  { month: "May", Student: 250, Teacher: 240, Professional: 270, Institutes: 200 },
  { month: "Jun", Student: 280, Teacher: 270, Professional: 300, Institutes: 220 },
  { month: "Jul", Student: 290, Teacher: 280, Professional: 310, Institutes: 230 },
  { month: "Aug", Student: 300, Teacher: 290, Professional: 320, Institutes: 240 },
  { month: "Sep", Student: 280, Teacher: 260, Professional: 290, Institutes: 250 },
  { month: "Oct", Student: 260, Teacher: 250, Professional: 270, Institutes: 260 },
  { month: "Nov", Student: 240, Teacher: 220, Professional: 250, Institutes: 270 },
  { month: "Dec", Student: 230, Teacher: 200, Professional: 240, Institutes: 280 },
];


const SupadminDashboard = () => {
  const { userData } = useGlobalContext();
  console.log("UserData : ", userData)
  return (
    <Box sx={{ padding: 3, display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
      {/* Summary Cards */}
      <Card sx={{ padding: 2 }}>
        <Typography variant="h6">Total Users : 2343</Typography>
        <Typography variant="body1">Students: 343</Typography>
        <Typography variant="body1">Teachers: 567</Typography>
        <Typography variant="body1">Professionals: 878</Typography>
      </Card>
      <Card sx={{ padding: 2 }}>
        <Typography variant="h6">Total Institute</Typography>
        <Typography variant="h4">62</Typography>
      </Card>
      <Card sx={{ padding: 2 }}>
        <Typography variant="h6">Total Jobs </Typography>
        <Typography variant="h4">38</Typography>
      </Card>
      <Card sx={{ padding: 2 }}>
        <Typography variant="h6">Total Question </Typography>
        <Typography variant="h4">10</Typography>
      </Card>
      <Card sx={{ padding: 2 }}>
        <Typography variant="h6">Total Comments</Typography>
        <Typography variant="h4">767</Typography>
      </Card>
      <Card sx={{ padding: 2 }}>
        <Typography variant="h6">Total Answer</Typography>
        <Typography variant="h4">62</Typography>
      </Card>
      <Card sx={{ padding: 2 }}>
        <Typography variant="h6">Supports</Typography>
        <Typography variant="h4">123</Typography>
      </Card>
      <Card sx={{ padding: 2 }}>
        <Typography variant="h6">Events</Typography>
        <Typography variant="h4">342</Typography>
      </Card>

      {/* Users Statistics */}
      <Card sx={{ gridColumn: 'span 2', padding: 2 }}>
        <Typography variant="h6">Users Statistics</Typography>
        <ChartJSLine data={statisticsData} />
      </Card>

      {/* Service Distribution (Doughnut Chart) */}
      <Card sx={{ gridColumn: 'span 1', padding: 2 }}>
        <Typography variant="h6">Service Distribution</Typography>
        <ChartJSDoughnut data={doughnutData} />
      </Card>

      <Card sx={{ gridColumn: 'span 2', padding: 2 }}>
        <Typography variant="h6">User Statistics</Typography>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <RechartsTooltip />
            <RechartsLegend />
            <Line
              type="monotone"
              dataKey="Student"
              stroke="#800080"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="Teacher"
              stroke="#FF4500"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="Professional"
              stroke="#32CD32"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="Institutes"
              stroke="#73ACE3"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>


      {/* Scheduled Appointments */}
      <Card sx={{ padding: 2 }}>
        <Typography variant="h6">Scheduled Appointments</Typography>
        <List>
          <ListItem>
            <ListItemText primary="In-Hospital Appointment, Dentist" secondary="Dr. S.S Duggal" />
          </ListItem>
          <ListItem>
            <ListItemText primary="In-Hospital Appointment, Pulmonologist" secondary="Dr. Prakash Khetani" />
          </ListItem>
        </List>
      </Card>

      {/* Top Doctors */}
      <Card sx={{ padding: 2 }}>
        <Typography variant="h6">Top Experts</Typography>
        <List>
          <ListItem>
            <Avatar />
            <ListItemText primary="Dr. S.S Duggal" secondary="4.5 Stars, 12 Years Exp." />
          </ListItem>
          <ListItem>
            <Avatar />
            <ListItemText primary="Mrs.Bhavika Mehta" secondary="4.7 Stars, 8 Years Exp." />
          </ListItem>
          <ListItem>
            <Avatar />
            <ListItemText primary="Mr. Prakash Khetani" secondary="4.8 Stars, 25 Years Exp." />
          </ListItem>
        </List>
      </Card>
    </Box>
  );
};

export default SupadminDashboard;
