import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Box, TextField, Typography, Button, Link as MuiLink } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Popup } from "layout/Popup";
import { useGlobalContext } from "global/context";

const fontFamily="'Quicksand',sans-serif"
function AdminLogin() {
  const navigate = useNavigate();
  const { setUser, setToken, api } = useGlobalContext();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (values) => {
    try {
      const res = await api.post("/admin/auth/login", values);
      setUser(res.data.userdata);
      setToken(res.data.token);
      localStorage.setItem("isLogin", true);
      Popup("success", "Successfully logged in");

      if (res.data.userdata.role === "superadmin") {
        navigate("/superadmin/dashboard");
      } else {
        navigate("/admin/dashboard");
      }
    } catch (error) {
      Popup("error", "Error during login");
    }
  };

  return (
    <Container fluid className="p-5">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6} className="d-flex flex-column justify-content-center">
          <Typography variant="h4" gutterBottom textAlign={"center"} color="primary" fontWeight="bold" fontFamily={fontFamily}>
            Admin Login
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    label="Password"
                    name="password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Box>
                <Box textAlign="center" my={2}>
                  <Button variant="contained" color="info" type="submit" sx={{fontFamily:fontFamily}}>
                    Login
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Box textAlign="center" my={2}>
            <MuiLink component={Link} to="/auth/admin/recover">
              Forgot Password?
            </MuiLink>
          </Box>
          {/* <p className="text-center" style={{fontFamily:fontFamily}}>
            Don't have an account?{" "}
            <strong>
              <Link to="/auth/admin/signup">Sign up</Link>
            </strong>
          </p> */}
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-center">
          <Box maxWidth="100%" textAlign="center">
            <Image
              src="https://img.freepik.com/free-vector/happy-freelancer-with-computer-home-young-man-sitting-armchair-using-laptop-chatting-online-smiling-vector-illustration-distance-work-online-learning-freelance_74855-8401.jpg?w=740&t=st=1720676868~exp=1720677468~hmac=4987bc80f01dc7c0c9f909788656865c1e4f8e49bf780c9d5dee526e09c88017"
              fluid
            />
          </Box>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminLogin;
